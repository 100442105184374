import React, { useEffect, useState } from 'react'
import './App.scss'
import CssBaseline from '@mui/material/CssBaseline'
import Revenue from './components/screens/Revenue/Revenue'
import { OtherScreen } from './components/screens/OtherScreen/OtherScreen'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ROUTES_LINKS } from './constants/ROUTES'
import BudgetScreen from './components/screens/BudgetScreen/BudgetScreen'
import DashBoard360 from './components/screens/Dashboard360/Dashboard360'
import { auth } from './services/firebase'
import PrivateRoute from './components/commons/PrivateRoute'
import NavigationContainer from './components/navigation/NavigationContainer'
import { LoginScreen } from './components/screens/LoginScreen/LoginScreen'
import BackendServices from './services/BackendServices'
import { IntlProvider } from 'react-intl'
import enJson from './lang/en.json'
import esJson from './lang/es.json'
import UserContext from './contexts/UserContext'

function loadLocaleData(locale: string): any {
    switch (locale) {
        case 'es':
            return esJson
        default:
            return enJson
    }
}

const App = () => {
    const [userLoading, setUserLoading] = useState<boolean>(true)
    const [locale, setLocale] = useState<'es' | 'en'>('es')
    const [backendServices, setBackendServices] = useState<BackendServices>()
    const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined)
    const [lastActivity, setLastActivity] = useState<number>(Date.now());
    const [loading, setLoading] = useState<boolean>(false)

    const idToken = (new URLSearchParams(window.location.search)).get('idToken')

    const isTokenValid = async (idToken: string)=>  {
        const backendServices = new BackendServices(idToken)
        const response = await backendServices.getUserInfo()
        // console.log('isTokenValid')
        // console.log(response)
        
        return response.status == 200
    }  


     // Agregar temporizador para cerrar sesión después de 30 minutos de inactividad
     useEffect(() => {
        const checkInactivity = () => {
          const currentTime = Date.now();
          const timeSinceLastActivity = currentTime - lastActivity;
          if (timeSinceLastActivity >= 30 * 60 * 1000) {
            // console.log('No activity in the last 30 minutes, logging out user');
            auth.signOut().then(() => {
                window.location.reload()
                // console.log('User logged out');
            }).catch((error) => {
              console.log(error.message);
            });
          }
        };
      
        const resetTimer = () => {
          setLastActivity(Date.now());
        };
      
        // document.addEventListener('mousemove', resetTimer);
        document.addEventListener('keydown', resetTimer);
        // document.addEventListener('scroll', resetTimer);
      
        const timer = setInterval(checkInactivity, 1800000);
      
        return () => {
          clearInterval(timer);
          document.removeEventListener('mousemove', resetTimer);
          document.removeEventListener('keydown', resetTimer);
          document.removeEventListener('scroll', resetTimer);
        };
    }, [auth, lastActivity]);

    

    useEffect(() => {        
        if(idToken) {
            isTokenValid(idToken)
            .then(ok=> {
                const backServices = new BackendServices(idToken)
                setBackendServices(backServices)
                backServices.getUserInfo().then((result) => {
                    setUserInfo({
                        ...result.data,
                    })
                })
                setUserLoading(false)
                localStorage.setItem('idToken', idToken)
                window.location.href='/'
             })
            .catch(error=> {window.location.href=`${process.env.REACT_APP_ROIBI_URI}/login`})
        } else {
            const idToken2 = localStorage.getItem('idToken')
            if(idToken2) {
                isTokenValid(idToken2)
                .then(ok=> {
                    const backServices = new BackendServices(idToken2)
                    setBackendServices(backServices)
                    backServices.getUserInfo().then((result) => {
                        setUserInfo({
                            ...result.data,
                        })
                    })
                    setUserLoading(false)                   
                 })
                .catch(error=> {window.location.href=`${process.env.REACT_APP_ROIBI_URI}/login`})
            }
        }

        if (locale === 'es') {
            setLocale('es')
        } else {
            setLocale('en')
        }

        // console.log('init')
        // console.log('retrieving user')
        // const unsubscribeCallback = auth.onAuthStateChanged((user) => {
        //     console.log(user)
        //     if (!user) {
        //         console.log('no user')
        //         setUserLoading(false)
        //         return
        //     }
        // console.log('starting back connection')
        // user?.getIdToken().then((token) => {
        //     const backServices = new BackendServices(token)
        //     setBackendServices(backServices)
        //     console.log('retrieving user info')
        //     backServices.getUserInfo().then((result) => {
        //         setUserInfo({
        //             ...result.data,
        //             photoURL: user?.photoURL,
        //         })
        //     })
        //     setUserLoading(false)
        // })
        // return () => {
        //     unsubscribeCallback()
        // }
        
    }, [])
        
   

    // console.log('locale', locale)

    return (
        <>
            <CssBaseline />
            <IntlProvider locale={locale} messages={loadLocaleData(locale)}>
                <UserContext.Provider value={{ user: userInfo, userLoading, backendServices, locale, setLocale, loading, setLoading }}>
                    <BrowserRouter>
                        <NavigationContainer>
                            <Routes>
                                <Route path="/" element={<PrivateRoute element={<BudgetScreen userInfo={userInfo} />} />} />
                                <Route path="login" element={<LoginScreen />} />
                                <Route path={ROUTES_LINKS.smartView} element={<OtherScreen />} />
                                <Route path={ROUTES_LINKS.dashboard} element={<DashBoard360 />} />
                                <Route path={ROUTES_LINKS.revenue} element={<Revenue screenTitle={'Revenue performance'} />} />
                                <Route path={ROUTES_LINKS.adr} element={<Revenue screenTitle={'ADR performances'} />} />
                                <Route path={ROUTES_LINKS.occupancy} element={<Revenue screenTitle={'Occupancies'} />} />
                            </Routes>
                        </NavigationContainer>
                    </BrowserRouter>
                </UserContext.Provider>
            </IntlProvider>
        </>
    )
}
export { UserContext }
export default App
