import { FC, useMemo, useState } from 'react'
import { ComposableMap, Geographies, Geography } from 'react-simple-maps'
import { GEOGRAPHY_URL } from '../../../constants/geo'
import {Alpha2Code} from 'i18n-iso-countries';
import {useIsMobile, useIsTablet} from '../../../utils/hooks';
import {useMediaQuery} from '@mui/material';
import worldData from '../../../data/world-countries.json'

// type Props = {
//     data: { country: Alpha2Code; value: number }[]
// }
// const WorldMap: FC<Props> = (props) => {
//     const { data } = props
//     // const [content, setContent] = useState('')
//     const maxReservationsValue: number = useMemo(
//         () => data.reduce((acc, item) => (item.value > acc ? item.value : acc), 0),
//         [data],
//     )


//     return (
//         <ComposableMap
//             // color={'#E4E5E6'}
//             // stroke="#E4E5E6"
//             projectionConfig={{
//                 scale: 85,
//                 center: [85, -70]
//             }}
//             viewBox="0 0 550 280"
//             projection="geoMercator"
//         >
//             <Geographies geography={GEOGRAPHY_URL}>
//                 {({ geographies }) =>
//                     geographies.map((geo) => {
//                         const countryId = geo.id
//                         const geoReservations: number = data.find((data) => data.country === countryId)?.value || 0
//                         const reservationsPercent = geoReservations / maxReservationsValue
//                         const finalPercent = 50 - Math.round(reservationsPercent * 20)
//                         return (
//                             <Geography
//                                 key={geo.rsmKey}
//                                 geography={geo}
//                                 // onMouseEnter={() => {
//                                 //     setContent(`${geo.properties.name}`)
//                                 // }}
//                                 // onMouseLeave={() => {
//                                 //     setContent('')
//                                 // }}
//                                 style={{
//                                     default: {
//                                         fill: geoReservations > 0 ? `hsl(200deg 100% ${finalPercent}%)` : '#E6E6EA',
//                                         outline: 'none',
//                                         stroke: '#FFFFFF', 
//                                         strokeWidth: 0.3, 
//                                     },
//                                     hover: {
//                                         fill: geoReservations > 0 ? `hsl(200deg 100% ${finalPercent}%)` : '#E6E6EA',
//                                         outline: 'none',
//                                         stroke: '#FFFFFF', 
//                                         strokeWidth: 0.3, 
//                                     },
//                                     pressed: {
//                                         fill: geoReservations > 0 ? `hsl(200deg 100% ${finalPercent}%)` : '#E6E6EA',
//                                         outline: 'none',
//                                         stroke: '#FFFFFF', 
//                                         strokeWidth: 0.3, 
//                                     },
//                                 }}
//                             />
//                         )
//                     })
//                 }
//             </Geographies>
//         </ComposableMap>
//     )
// }

// export default WorldMap


type Props = {
    data: { country: Alpha2Code; value: number }[];
}

const WorldMap: FC<Props> = (props) => {
    const { data } = props;
    const maxReservationsValue: number = useMemo(
        () => data.reduce((acc, item) => (item.value > acc ? item.value : acc), 0),
        [data],
    );

    // Clg para corroborar si hay data
    // console.log("Contenido de data:");
    // data.forEach((data) => {
    //     console.log("data.country:", data.country);
    //     console.log("data.value", data.value)
    // });

    return (
        <ComposableMap
            projectionConfig={{
                scale: 85,
                center: [85, -70],
            }}
            viewBox="0 0 550 280"
            projection="geoMercator"
        >
            <Geographies geography={worldData}>
                {({ geographies }) =>
                    geographies.map((geo) => {
                        const countryId = geo.properties.ISO_A3_EH;
                        // console.log(countryId)
                        const geoReservations: number = data.find((data) => data.country === countryId)?.value || 0;
                        // console.log(geoReservations)
                        const reservationsPercent = geoReservations / maxReservationsValue;
                        const finalPercent = 50 - Math.round(reservationsPercent * 20);

                        return (
                            <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                style={{
                                    default: {
                                        fill: geoReservations > 0 ? `hsl(200deg 100% ${finalPercent}%)` : '#E6E6EA',
                                        outline: 'none',
                                        stroke: '#FFFFFF',
                                        strokeWidth: 0.3,
                                    },
                                    hover: {
                                        fill: geoReservations > 0 ? `hsl(200deg 100% ${finalPercent}%)` : '#E6E6EA',
                                        outline: 'none',
                                        stroke: '#FFFFFF',
                                        strokeWidth: 0.3,
                                    },
                                    pressed: {
                                        fill: geoReservations > 0 ? `hsl(200deg 100% ${finalPercent}%)` : '#E6E6EA',
                                        outline: 'none',
                                        stroke: '#FFFFFF',
                                        strokeWidth: 0.3,
                                    },
                                }}
                            />
                        );
                    })
                }
            </Geographies>
        </ComposableMap>
    );
};

export default WorldMap;

