import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { FC } from 'react'
import { Box, Card, CardContent} from '@mui/material'
import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  TimeScale, 
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

type CardProps = {
    graphData: {
        labels: string[]
        datasets: {
            label: string
            data: number[]
        }[]
    }
    graphOptions?: {
        borderColor: string
        backgroundColor: string
        lineTension: number
    }
    title: string
    value: number
}

const data = {
    labels: ['Oct 21', 'Nov 21', 'Dec 21', 'Jan 22', 'Feb 22', 'Mar 22', 'Apr 22', 'May 22', 'Jun 22', 'Jul 22', 'Aug 22', 'Sep 22', 'Oct 22'],
    datasets: [
      {
        label: 'Highest',
        data: [30, 35, 30, 30, 35, 25, 30, 30, 35, 25, 30, 40, 35],
        fill: false,
        backgroundColor: 'rgba(84, 220, 127, 1)',
        borderColor: 'rgba(84, 220, 127, 1)',
      },
      {
        label: 'Total',
        data: [20, 20, 25, 25, 15, 15, 25, 20, 25, 15, 20, 30, 30],
        fill: false,
        backgroundColor: 'rgba(44, 200, 215, 1)',
        borderColor: 'rgba(44, 200, 215, 1)',
      },
      {
        label: 'Lowest',
        data: [10, 15, 15, 20, 10, 20, 15, 10, 20, 10, 10, 20, 20],
        fill: false,
        backgroundColor: 'rgba(244, 67, 54, 1)',
        borderColor: 'rgba(244, 67, 54, 1)',
      },
    ]
  };

  
const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      datalabels: {
        display: false
    }
    },
    scales: {
      y: {
        min: 0,
        max: 50,
        ticks: {
          callback: (string: any) => string,
          stepSize: 5,
        }
      },
      x: {
        grid: {
          display: false
        }
      }
    }
    
  };
  
const LineChartRevenue:  FC<CardProps> = (props) =>{
    const {graphData, title, value} = props

    return(
        <Card>
            <Line
            options={options}
            data={data}
            />
        </Card> 
    )  
}

export default LineChartRevenue