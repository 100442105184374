import { defineMessages } from 'react-intl'

export const BudgetMessages = defineMessages({
    currentMonth: { id: 'currentMonth', defaultMessage: 'Current Month' },
    allTypologies: { id: 'allTypologies', defaultMessage: 'All typologies' },
    apply: { id: 'apply', defaultMessage: 'Apply' },
    property: { id: 'property', defaultMessage: 'Property' },
    test: { id: 'test', defaultMessage: 'test' },
    test2: { id: 'test2', defaultMessage: 'this is Test 2' },
    budgetRevPAR: { id: 'budgetRevPAR', defaultMessage: 'Rev Par' },
    budgetBudget: { id: 'budgetBudget', defaultMessage: 'Forecast' },
    budgetRevenue: { id: 'budgetRevenue', defaultMessage: 'Revenue' },
    budgetADR: { id: 'budgetADR', defaultMessage: 'ADR' },
    budgetOccupancy: { id: 'budgetOccupancy', defaultMessage: 'Occupancy' },
    budgetToGo: { id: 'budgetToGo', defaultMessage: 'To Go' },
    budgetNow: { id: 'budgetNow', defaultMessage: 'Now' },
    budgetLastYear: { id: 'budgetLastYear', defaultMessage: 'Last Year' },
    country: { id: 'country', defaultMessage: 'Country' },
    dorm: { id: 'dorm', defaultMessage: 'Dorm' },
    room: { id: 'room', defaultMessage: 'Room' },
    apart: { id: 'apart', defaultMessage: 'Apart' },
    budgetRevenueDescription: { id: 'budgetRevenueDescription', defaultMessage: 'Revenue shows the sum of incomes as is reported on the PMS. According to different configurations on the OTAs, the report will show the metric including commissions and taxes.'},
    budgeteRevParDescription: { id: 'budgeteRevParDescription', defaultMessage: 'RevPAR shows the produced incomes per every unit available for sale.'},
    budgetADRDescription: { id: 'budgetADRDescription', defaultMessage: 'ADR shows the average daily rate. It divides the revenue over the total units sold.'},
    BudgetOccupancyDescription: { id: 'BudgetOccupancyDescription', defaultMessage: 'Occupancy shows the percentage of sold units over the selected period. It results from the division between occupied units and available units.'},
    budgetCountryRevenueDescription: { id: 'budgetCountryRevenueDescription', defaultMessage: "The table shows the top countries that generated the most revenue in the selected period, taking into account the guest's country informed in the PMS."},
    budgetCountryOccupancyDescription: { id: 'budgetCountryOccupancyDescription', defaultMessage: "The table shows the top countries that produced the highest occupancy in the selected period, taking into account the guest's country informed in the PMS."},
    noDataToShow: {id: 'noDataToShow', defaultMessage: "No data available to display."},
    backToRoibiConsole: {id: 'backToRoibiConsole', defaultMessage: "Back to Roibi Console"}
})

export const MenuMessages = defineMessages({
    menuAccount: { id: 'menuAccount', defaultMessage: 'Account' },
    menuSubscriptions: { id: 'menuSubscriptions', defaultMessage: 'Subscriptions' },
    menuContact: { id: 'menuContact', defaultMessage: 'Guía rápida' },
    menuHelpCenter: { id: 'menuHelpCenter', defaultMessage: 'Help Center' },
    menuLogout: { id: 'menuLogout', defaultMessage: 'Logout' },
})
