import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import * as i18nIsoCountries from 'i18n-iso-countries'
import { getName } from 'i18n-iso-countries'
import { isoToCountryCode } from 'flagpack-core'
import * as enJson from 'i18n-iso-countries/langs/en.json'
import * as esJson from 'i18n-iso-countries/langs/es.json'
import { styled } from '@mui/material/styles'
import InfoPopover from '../../screens/BudgetScreen/PopupInfoCharts'
import { FC } from 'react'
import { BudgetMessages } from '../../../lang/Messages'
import { useIntl } from 'react-intl'
import {useUserContext} from '../../../utils/hooks';
import { Alpha2Code } from 'i18n-iso-countries'

i18nIsoCountries.registerLocale(enJson)
i18nIsoCountries.registerLocale(esJson)

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}))

type Props = {
    data: {
        country: Alpha2Code
        value: any
    }[]
    columnName: string
}

const CountriesDataTable: FC<Props> = (props) => {
    const { locale } = useUserContext()
    const { data, columnName } = props
    const { formatMessage } = useIntl()
    return (
        <TableContainer component={Paper} className="country-table">
            <div className="info-popup-table">
                <InfoPopover
                    title={columnName === 'Revenue'? `${formatMessage(BudgetMessages.budgetRevenue)}` : columnName === 'Ingresos' ? `${formatMessage(BudgetMessages.budgetRevenue)}` : `${formatMessage(BudgetMessages.budgetOccupancy)}` }
                    textPopover={columnName === 'Revenue'? `${formatMessage(BudgetMessages.budgetCountryRevenueDescription)}` : columnName === 'Ingresos' ? `${formatMessage(BudgetMessages.budgetCountryRevenueDescription)}` : `${formatMessage(BudgetMessages.budgetCountryOccupancyDescription)}` }
                />
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>  {formatMessage(BudgetMessages.country)} </TableCell>
                        <TableCell colSpan={2} align="right">                            
                            {' '}
                            {columnName}{' '}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.slice(0, 10).map((row) => {
                        // const countryName = getName(row.country, locale, { select: 'all' })
                        // const countryCode = isoToCountryCode(row.country)
                        let countryName, countryCode
                        try {
                            countryName = getName(row.country, locale, { select: 'official' })
                            countryCode = isoToCountryCode(row.country)
                        } catch (e) {
                            console.error(`Error getting country name or code for ${row.country}: ${e}`)
                            return null
                        }
                        if (!countryName) return null
                        return (
                            <StyledTableRow
                                key={row.country}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                className="country-table-row"
                            >
                                <TableCell colSpan={1} align="left">
                                    {/* eslint-disable-next-line @typescript-eslint/no-var-requires */}
                                    <div className="country-table-row-flag">
                                        <img src={require(`flagpack-core/lib/flags/l/${countryCode}.svg`)} />
                                    </div>
                                </TableCell>
                                <TableCell colSpan={2} align="left">
                                    <div className="country-table-row-labels">
                                        <label>{row.country.toUpperCase()}</label>
                                        <span>{countryName}</span>
                                    </div>
                                </TableCell>
                               <TableCell colSpan={1} className="country-table-row-reservations-section" align="right">
                                    {/* <div>$ {row.value}</div> */}
                                    <div>{columnName === 'Revenue' ? `$ ${row.value}` : columnName === 'Ingresos' ? `$${row.value}` : columnName === 'Ocupación' ? `${row.value}%` : `${row.value}%`}</div>
                                </TableCell>
                            </StyledTableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


export default CountriesDataTable