import { FC } from 'react'
import './Dashboard360.scss'
// import DashBoardMap from '../../commons/Map/DashboardMap'
import CardWithLineChart from '../../commons/CardWithLineChart/CardWithLineChart'
import CardWithDoughnutChart from '../../commons/CardWithDoughnutChart/CardWithDoughnutChart'
import CardValues from '../../commons/CardValues/CardValues'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import CardWithRadarChart from '../../commons/CardWithRadarChart/CardWithRadarChart'

const labels = ['first', 'second', 'third', 'quarter', 'fifth', 'last']
const dataLine = {
    labels,
    datasets: [
        {
            label: '',
            data: [500000, 550000, 400000, 570000, 580000, 600000],
        },
    ],
}
const data = [10, 20, 40] // siempre tienen que venir los tres valores

const dataValues = [
    { title: 'Top', color: '#00B043', value: 4250 },
    { title: 'Avg.', color: 'rgba(0, 176, 67, 0.5)', value: 262 },
    { title: 'Lowest', color: 'rgba(255, 121, 119, 0.5)', value: 201 },
]

const mockRadarData = {
    booking: [2, 9, 3, 5, 2, 3],
    airDNA: [9, 1, 4, 5, 7, 3],
    hostelWorld: [6, 2, 5, 5, 7, 9],
}
const Dashboard360: FC = () => {
    return (
        <div className={'dashboard-360  '}>


            <div className="cards-container">
                <div className="cards-column">
                    <Card className="weather-card">Weather</Card>
                    <div className="card-roi-ds">
                        <CardWithDoughnutChart data={data} title="RoiDs" />
                    </div>
                    <div className="card-roi-rev">
                        <CardWithRadarChart data={mockRadarData} />
                    </div>
                    <div className="card-roi-ml">
                        <CardValues values={dataValues} title="RoiML" />
                    </div>
                </div>
            </div>
            <div className="map-container">
                <div className="resume-row">
                    <CardWithLineChart graphData={dataLine} chipLabel="5 % this month" title="This Month" value='600k' />
                    <CardWithLineChart graphData={dataLine} chipLabel="5 % this month" title="Top Day" value='600k' />
                    <CardWithLineChart graphData={dataLine} chipLabel="5 % this month" title="Less Performant Day" value='600k' />
                </div>
                <div className="map">
                    {/* <DashBoardMap /> */}
                </div>
            </div>
        </div>
    )
}

export default Dashboard360
