type Location = {
    name: string
    position: any
}
type BarData = {
    max: number
    value: number
}
type MockData = {
    user?: {
        name: string
        lastName: string
    }
    business: {
        name: string
        locations: Location[]
        competitors: Location[]
    }
    dashboard: {
        cards: {
            title: string
            dataKey: string
        }[]

        resume: {
            title: string
            budget: BarData
            now: BarData
            toGo: BarData
        }[]
    }
}

const mockData: MockData = {
    user: {
        name: 'Juan',
        lastName: 'Perez',
    },
    business: {
        name: 'Che Hostels',
        locations: [
            {
                name: 'Hostel 1',
                position: [43.2902, -2.989],
            },
            {
                name: 'Hostel 2',
                position: [43.2892, -2.9905],
            },
            {
                name: 'Hostel 3',
                position: [43.289, -2.997],
            },
            {
                name: 'Hostel 4',
                position: [43.2962, -2.9892],
            },
            {
                name: 'Hostel 5',
                position: [43.2982, -2.9875],
            },
        ],
        competitors: [
            {
                name: 'Hostel 1',
                position: [43.2902, -2.989],
            },
            {
                name: 'Hostel 2',
                position: [43.2852, -2.9945],
            },
            {
                name: 'Hostel 3',
                position: [43.287, -2.999],
            },
            {
                name: 'Hostel 4',
                position: [43.2912, -2.9822],
            },
            {
                name: 'Hostel 5',
                position: [43.2992, -2.9855],
            },
        ],
    },
    dashboard: {
        cards: [
            {
                title: 'Info de una card',
                dataKey: 'uv',
            },
            {
                title: 'Info de otra card',
                dataKey: 'pv',
            },
            {
                title: 'Otro tipo de cosita',
                dataKey: 'amt',
            }
        ],

        resume: [
            {
                title: 'sdsd',
                budget: {
                    max: 1,
                    value: 1,
                },
                now: {
                    max: 1,
                    value: 1,
                },
                toGo: {
                    max: 1,
                    value: 1,
                },
            },
        ],
    },
}

export default mockData
