import { FC, useState } from 'react'
import './CardWithRadarChart.scss'
import Typography from '@mui/material/Typography'
import { Card, CardContent, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Radar } from 'react-chartjs-2'
import {Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, ChartOptions} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { RoiReviewIcon } from '../../../assets/Icons'
import 'chart.js/auto'
import * as React from 'react'

ChartJS.register(ArcElement, Tooltip, Legend)

type CardProps = {
    data: {
        booking: number[]
        airDNA: number[]
        hostelWorld: number[]
    }
    title?: string
}

const defaultRadarData = {
    labels: ['Accuracy', 'Value', 'Location', 'Communication', 'Cleanliness', 'Check-In'],
    datasets: [
        {
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
        }
    ],
    
}


const CardWithRadarChart: FC<CardProps> = (props) => {
    const { title, data } = props
    const [dataSource, setDataSource] = useState<'booking' | 'airDNA' | 'hostelWorld'>('booking')

    const finalData = {
        ...defaultRadarData,
        datasets: [
            {
                ...defaultRadarData.datasets[0],
                data: data[dataSource],
            },
        ],
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (value !== 'booking' && value !== 'airDNA' && value !== 'hostelWorld') return
        setDataSource(value)
    }

    const radarOptions: ChartOptions<'radar'> = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio:1.3,
        scales: {
            r: {
                min: 0,
                max: 10,
            },
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: false
            }
        }
    }

    return (
        <Card  className='radar-chart-dashboard'>
            <div className='card-with-radar-chart'>
                <div className='title-and-radio-container'>
                    <Typography color="text.secondary" gutterBottom className='radar-chart-title'>
                        <RoiReviewIcon /> RoiReview {title}
                    </Typography>
                    <CardContent className="score-and-radioButtons">
                        <Typography>My Score</Typography>
                        <RadioGroup onChange={onChange} value={dataSource}>
                            <FormControlLabel className="radio-button-booking" control={<Radio value="booking" />} label="Booking" />
                            <FormControlLabel className="radio-button-airDna" control={<Radio value="airDNA" />} label="AirDNA" />
                            <FormControlLabel className="radio-button-hostelWorld" control={<Radio value="hostelWorld" />} label="Hostel World" />
                        </RadioGroup>
                    </CardContent>
                </div>    
                
                <div className="chart-container">
                    <Radar options={radarOptions} data={finalData} />{' '}
                </div>
                
            </div>
        </Card>
    )
}

export default CardWithRadarChart
