import React, { FunctionComponent } from 'react'
import { CircularProgress } from '@mui/material'
import loadingForecastLogo from '../../../assets/logo-grande-redirect.png'

const LoadingScreen: FunctionComponent = () => {
    return (
        <div
            style={{
                height: 'calc(100vh - var(--topbar-height))',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <img style={{ maxWidth: '300px' }} src={loadingForecastLogo} /> <CircularProgress />
        </div>
    )
}

export default LoadingScreen
