import { FC } from 'react'
import mockData from '../../../constants/mock'
// import DashBoardMap from '../../commons/Map/DashboardMap'
import InfoCard from '../../commons/InfoCard'
import './Revenue.scss'
import { Card, CardContent, CardHeader} from '@mui/material';
import Typography from '@mui/material/Typography'
import CardWithLineChart from '../../commons/CardWithLineChart/CardWithLineChart'
import LineChartRevenue from '../../commons/LineChartRevenue/LineChartRevenue'

type Props = {
    screenTitle: string
}

// const data = [
//     {
//         name: 'Page A',
//         uv: 4000,
//         pv: 2400,
//         amt: 2400,
//     },
//     {
//         name: 'Page B',
//         uv: 3000,
//         pv: 1398,
//         amt: 2210,
//     },
//     {
//         name: 'Page C',
//         uv: 2000,
//         pv: 9800,
//         amt: 2290,
//     },
//     {
//         name: 'Page D',
//         uv: 2780,
//         pv: 3908,
//         amt: 2000,
//     },
//     {
//         name: 'Page E',
//         uv: 1890,
//         pv: 4800,
//         amt: 2181,
//     },
//     {
//         name: 'Page F',
//         uv: 2390,
//         pv: 3800,
//         amt: 2500,
//     },
//     {
//         name: 'Page G',
//         uv: 3490,
//         pv: 4300,
//         amt: 2100,
//     },
//     {
//         name: 'Page A',
//         uv: 4000,
//         pv: 2400,
//         amt: 2400,
//     },
//     {
//         name: 'Page B',
//         uv: 3000,
//         pv: 1398,
//         amt: 2210,
//     },
//     {
//         name: 'Page C',
//         uv: 2000,
//         pv: 9800,
//         amt: 2290,
//     },
//     {
//         name: 'Page D',
//         uv: 2780,
//         pv: 3908,
//         amt: 2000,
//     },
//     {
//         name: 'Page E',
//         uv: 1890,
//         pv: 4800,
//         amt: 2181,
//     },
//     {
//         name: 'Page F',
//         uv: 2390,
//         pv: 3800,
//         amt: 2500,
//     },
//     {
//         name: 'Page G',
//         uv: 3490,
//         pv: 4300,
//         amt: 2100,
//     },
//     {
//         name: 'Page A',
//         uv: 4000,
//         pv: 2400,
//         amt: 2400,
//     },
//     {
//         name: 'Page B',
//         uv: 3000,
//         pv: 1398,
//         amt: 2210,
//     },
//     {
//         name: 'Page C',
//         uv: 2000,
//         pv: 9800,
//         amt: 2290,
//     },
//     {
//         name: 'Page D',
//         uv: 2780,
//         pv: 3908,
//         amt: 2000,
//     },
//     {
//         name: 'Page E',
//         uv: 1890,
//         pv: 4800,
//         amt: 2181,
//     },
//     {
//         name: 'Page F',
//         uv: 2390,
//         pv: 3800,
//         amt: 2500,
//     },
//     {
//         name: 'Page G',
//         uv: 3490,
//         pv: 4300,
//         amt: 2100,
//     },
//     {
//         name: 'Page A',
//         uv: 4000,
//         pv: 2400,
//         amt: 2400,
//     },
//     {
//         name: 'Page B',
//         uv: 3000,
//         pv: 1398,
//         amt: 2210,
//     },
//     {
//         name: 'Page C',
//         uv: 2000,
//         pv: 9800,
//         amt: 2290,
//     },
//     {
//         name: 'Page D',
//         uv: 2780,
//         pv: 3908,
//         amt: 2000,
//     },
//     {
//         name: 'Page E',
//         uv: 1890,
//         pv: 4800,
//         amt: 2181,
//     },
//     {
//         name: 'Page F',
//         uv: 2390,
//         pv: 3800,
//         amt: 2500,
//     },
//     {
//         name: 'Page G',
//         uv: 3490,
//         pv: 4300,
//         amt: 2100,
//     },
// ]

const labels = ['first', 'second', 'third', 'quarter', 'fifth', 'last']
const dataLine = {
    labels,
    datasets: [
        {
            label: '',
            data: [500000, 550000, 400000, 570000, 580000, 600000],
        },
    ],
}
const dataLineRevenue = {
    labels,
    datasets: [
        {
            label: '',
            data: [],
        },
    ],
}

const Revenue: FC<Props> = (props) => {
    const { cards } = mockData.dashboard
    const {screenTitle} = props
    return (
        <div className={'revenue-screen'}>
            <div className='card-container'>
                <div className="resume-row">
                    <CardWithLineChart graphData={dataLine} chipLabel="5 % this month" title="This Month" value='600k' />
                    <CardWithLineChart graphData={dataLine} chipLabel="5 % this month" title="Top Day" value='600k' />
                    <CardWithLineChart graphData={dataLine} chipLabel="5 % this month" title="Less Performant Day" value='600k' />
                </div>
                <div className="charts-container">
                    <Card className="chart">
                        <CardHeader
                            title={
                                <Typography variant="h6">
                                    {screenTitle}
                                </Typography>
                            }
                        ></CardHeader>
                        <CardContent>
                            <LineChartRevenue graphData={dataLineRevenue} title='Revenue performance' value={0} />
                        </CardContent>
                    </Card>
                </div>
            </div>
           
            {/* <DashBoardMap /> */}
            

        </div>
        // <div className={'revenue-screen'}>
        //     <div className="data-container">
        //         <div className="filters-container"></div>
        //         <div className="cards-container">
        //             <div className="resume-row">
        //                 <Card>
        //                     <CardContent className="card">
        //                         This Month
        //                     </CardContent>
        //                 </Card>
        //                 <Card>
        //                     <CardContent className="card">Top Day</CardContent>
        //                 </Card>
        //                 <Card>
        //                     <CardContent className="card">
        //                         Less Performant Day
        //                     </CardContent>
        //                 </Card>
        //             </div>
        //         </div>

            //     <div className="charts-container">
            //         <Card className="chart">
            //             <CardHeader
            //                 title={
            //                     <Typography variant="h6">
            //                         {screenTitle}
            //                     </Typography>
            //                 }
            //             ></CardHeader>
            //         </Card>
            //     </div>
            // </div>

        //     <DashBoardMap />
        // </div>
    )
}

export default Revenue