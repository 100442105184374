import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Breadcrumbs, Button, CircularProgress, Icon, IconButton, Link, ListItem, TextField, Typography } from '@mui/material'
import { FC, useMemo, useState, useEffect } from 'react'
import { useIsMobile, useToggle, useUserContext } from '../../../utils/hooks'
import Drawer from '@mui/material/Drawer'
import ListItemButton from '@mui/material/ListItemButton'
import List from '@mui/material/List'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useIntl } from 'react-intl'
import { BudgetMessages } from '../../../lang/Messages'
import { format } from 'date-fns'
import { es, enUS } from 'date-fns/locale'
import { upperFirst } from 'lodash'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import backToConsoleIcon from '../../../assets/home-icon.png'

const grupoCheProperty = {
    id: "2b0cdcf1-e746-43c1-b53b-8ed87c801f68",
    name: 'Grupo Che',
    typologies: [
        { "alias": "dorm", "visualName": "Dormitorios" },
        { "alias": "room", "visualName": "Privadas" },
        { "alias": "global", "visualName": "Global" }
    ],
    currency: '$',
}

type Props = {
    onFilterChange: (filters: { period: string; propertyName: string; formOfSale: 'dorm' | 'room' | 'global'| 'apart' }) => void
    applyFilters: () => Promise<void>
    actualFilters: { period: string; propertyName: string; formOfSale: 'dorm' | 'room' | 'global'| 'apart' }
    properties?: Property[]
    typologies?: Typology[]
}

type FilterProps = {
    label: string
    options: string[] | { value: string; label: string }[]
    onChange?: (option: string) => void
    value: string | { value: string; label: string }
    properties?: Property[]
}

const getDateLocale = (locale: string) => {
    if (locale === 'es') {
        return es
    } else return enUS
}

const Filter: FC<FilterProps> = (props) => {
    const { label, options, onChange, value, properties } = props
    const realValue = typeof value === 'string' ? { value, label: value } : value
    const realOptions = options.map((option) => (typeof option === 'string' ? { value: option, label: option } : option))
   

    return (
        <Autocomplete
            disablePortal
            value={realValue}
            onChange={(event, select) => select && onChange && onChange(select.value)}
            options={realOptions}
            inputValue={realValue.label}
            renderInput={(params) => <TextField variant="filled" {...params} label={label} />}
            size="small"
            className="top-bar-filter"
        />
    )
}

const FilterMobile: FC<
    FilterProps & {
        isOpen?: boolean
        onOpen: () => void
        onClose: () => void
    }
> = (props) => {
    const { label, options, onChange, isOpen, onOpen, onClose } = props

    const [activeState, setActiveStates] = useState<{ [key: string]: boolean }>(
        options
            .map((option) => (typeof option === 'string' ? option : option.value))
            .reduce(
                (acc, option) => ({
                    ...acc,
                    [option]: false,
                }),
                {},
            ),
    )

    const [lastSelected, setLastSelected] = useState('')

    const [currentLabel, setCurrentLabel] = useState(label)

    const handleListItemActive = (option: string) => {
        setActiveStates((prevActiveStates) => {
            const newActiveStates = { ...prevActiveStates }
            if (lastSelected) {
                newActiveStates[lastSelected] = false
            }
            newActiveStates[option] = !newActiveStates[option]
            setLastSelected(option)

            const selectedOption = (() => {
                for (const o of options) {
                    if (typeof o === 'string') {
                        continue
                    }

                    if (o.value === option) {
                        return o
                    }
                }

                return undefined
            })()

            const newLabel = selectedOption ? selectedOption.label : label

            setCurrentLabel(newLabel)

            onClose()

            return newActiveStates
        })
    }

    return (
        <Accordion expanded={!!isOpen} elevation={0} className="filter-accordion">
            <AccordionSummary onClick={onOpen} expandIcon={<ExpandLessIcon />} aria-controls={`${label}-content`} id={`${label}-header`}>
                {currentLabel}
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    {options.map((option) => {
                        const isString = typeof option === 'string'
                        const label = isString ? option : option.label
                        const value = isString ? option : option.value
                        return (
                            <ListItemButton
                                className={activeState[value] ? 'activar' : ''}
                                onClickCapture={() => handleListItemActive(value)}
                                onClick={() => onChange && onChange(value)}
                                key={value}
                            >
                                {label}
                            </ListItemButton>
                        )
                    })}
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

type FilterOption = {
    value: string
    label: string
}

type CustomFilterProps = {
    datesOptions: FilterOption[]
    formOfSaleOptions: FilterOption[]
}

const sortOptionsAlphabetically = (options: FilterOption[]): FilterOption[] => {
    return options.sort((a, b) => a.label.localeCompare(b.label));
}

const DesktopFilters: FC<Props & CustomFilterProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const { onFilterChange, datesOptions, properties, actualFilters, applyFilters } = props
    const [formOfSaleOptions, setFormOfSaleOptions] = useState(props.formOfSaleOptions)

    const onChangeProperty = (propertyName: string) => {
        onFilterChange({
            ...actualFilters,
            propertyName,   
        })
        
        setFormOfSaleOptions(properties?.find(p => p.name === propertyName)?.typologies.map(t => {
            return {
                value: t.alias,
                label: t.visualName === 'Apartamentos' ? formatMessage(BudgetMessages.apart) :
                        t.visualName === 'Dormitorios' ? formatMessage(BudgetMessages.dorm) :
                        t.visualName === 'Privadas' ? formatMessage(BudgetMessages.room) : t.visualName,
            }
        }) || [])
    }

    const onChangePeriod = (period: string) => {
        onFilterChange({
            ...actualFilters,
            period,
        })
    }

    const onChangeformOfSale = (formOfSale: string) => {
        onFilterChange({
            ...actualFilters,
            formOfSale: formOfSale as 'dorm' | 'room' | 'global' | 'apart'            
        })

    }

    const formOfSaleValue = formOfSaleOptions?.find((option) => option.value === actualFilters.formOfSale) || { label: '', value: '' }
    const dateValue = datesOptions.find((option) => option.value === actualFilters.period) || { label: '', value: '' }
    const { formatMessage } = useIntl()

    const handleBackToConsole = ()=>{
        localStorage.removeItem('idToken')
        window.location.href=`${process.env.REACT_APP_ROIBI_URI}`
    }

    const submit = () => {
        setLoading(true)
        applyFilters().finally(() => setLoading(false))
    }

    // Ordenar las propiedades alfabéticamente
    const sortedProperties = sortOptionsAlphabetically(properties?.map(p => ({
        value: p.name,
        label: p.name
    })) || []);

    return (
        <div className="filters-bar desktop">
            {/* <Filter label="Type of building" />
            <Filter label="City" /> */}
            <img src={backToConsoleIcon} alt="home-icon" style={{height: '40px', width: '40px', cursor: 'pointer'}} onClick={handleBackToConsole} />
            <Filter
                value={actualFilters.propertyName}
                onChange={onChangeProperty}
                options={sortedProperties}
                label={formatMessage(BudgetMessages.property)}
            />
            <Filter 
                value={dateValue} 
                options={datesOptions} 
                label={formatMessage(BudgetMessages.currentMonth)} 
                onChange={onChangePeriod} 
            />
            <Filter
                value={formOfSaleValue} 
                onChange={onChangeformOfSale}
                options={formOfSaleOptions || []} 
                label={formatMessage(BudgetMessages.allTypologies)} 
            />
            <Button onClick={submit} className="button-filter" disableElevation variant="contained">
                {loading ? <CircularProgress color='inherit' size={25} /> : formatMessage(BudgetMessages.apply)}
            </Button>
        </div>
    )
}

type CustomFilterPropsMobile = {
    datesOptions: FilterOption[]
    formOfSaleOptions: FilterOption[]
    propertyNameOptions: FilterOption[]
}
const MobileFilters: FC<Props & CustomFilterPropsMobile> = (props) => {
    const { onFilterChange, datesOptions, properties, actualFilters, applyFilters, propertyNameOptions } = props
    const [formOfSaleOptions, setFormOfSaleOptions] = useState(props.formOfSaleOptions)
    
    const onChangeProperty = (propertyName: string) => {
        onFilterChange({
            ...actualFilters,
            propertyName,
        })

        setFormOfSaleOptions(properties?.find(p => p.name === propertyName)?.typologies.map(t => {
            return {
                value: t.alias,
                label: t.visualName === 'Apartamentos' ? formatMessage(BudgetMessages.apart) :
                     t.visualName === 'Dormitorios' ? formatMessage(BudgetMessages.dorm) : 
                     t.visualName === 'Privadas' ? formatMessage(BudgetMessages.room) : t.visualName,
            }
        }) || [])
    }

    const onChangePeriod = (period: string) => {
        onFilterChange({
            ...actualFilters,
            period,
        })
    }

    const onChangeformOfSale = (formOfSale: string) => {
        onFilterChange({
            ...actualFilters,
            formOfSale: formOfSale as 'dorm' | 'room' | 'global' | 'apart',
        })
    }

    const { state: isOpenMenu, toggle: toggleMenu, setFalse: closeMenu } = useToggle(false)
    const [accordionTabOpened, setAccordionTabOpened] = useState<string>('')
    const onFilterToggle = (tab: string) => {
        setAccordionTabOpened((prevState) => (prevState === tab ? '' : tab))
    }
    const formOfSaleValue = formOfSaleOptions.find((option) => option.value === actualFilters.formOfSale) || { label: '', value: '' }
    const dateValue = datesOptions.find((option) => option.value === actualFilters.period) || { label: '', value: '' }
    const propertyNameValue = propertyNameOptions.find((option) => option.value === actualFilters.propertyName) || { label: '', value: '' }
    const { formatMessage } = useIntl()

    const [propertyLabel, setPropertyLabel] = useState<string>('')
    const [dateLabel, setDateLabel] = useState<string>('')
    const [formOfSaleLabel, setFormOfSaleLabel] = useState<string>('')

    const handlePropertyChange = (option: string) => {
        onChangeProperty(option)
        setPropertyLabel(option)
    }

    const handleDateChange = (option: string) => {
        onChangePeriod(option)
        setDateLabel(option)
    }

    const handleFormOfSaleChange = (option: string) => {
        onChangeformOfSale(option)
        setFormOfSaleLabel(option)
    }


    const applyFiltersAndToggleMenu = () => {
        applyFilters();
        closeMenu();
    };

    // Ordenar las propiedades alfabéticamente
    const sortedPropertyNameOptions = sortOptionsAlphabetically(propertyNameOptions);
    

    return (
        <div className="filters-bar mobile">
            <IconButton onClick={toggleMenu} className="mobile-menu-button">
                {isOpenMenu ? <Icon>close</Icon> : <Icon>menu</Icon>}
            </IconButton>
            {
                <Drawer className="budget-mobile-filters-drawer" anchor={'left'} open={isOpenMenu} onClose={closeMenu}>
                    {/* <Divider className="divider" /> */}
                    <div className="subtitle">
                        <Icon>tune</Icon>
                        <label>Filtros</label>
                        <Button onClick={applyFiltersAndToggleMenu} className="button-filter-mobile" disableElevation variant="contained">
                            {formatMessage(BudgetMessages.apply)}
                        </Button>
                    </div>

                    <FilterMobile
                        isOpen={accordionTabOpened === 'Property'}
                        onOpen={() => onFilterToggle('Property')}
                        options={sortedPropertyNameOptions}
                        value={propertyNameValue}
                        label={formatMessage(BudgetMessages.property)}
                        onChange={handlePropertyChange}
                        onClose={() => setAccordionTabOpened('')}
                    />
                    <FilterMobile
                        value={dateValue}
                        isOpen={accordionTabOpened === 'Current month'}
                        onOpen={() => onFilterToggle('Current month')}
                        options={datesOptions}
                        label={formatMessage(BudgetMessages.currentMonth)}
                        onChange={handleDateChange}
                        onClose={() => setAccordionTabOpened('')}
                    />
                    <FilterMobile
                        value={formOfSaleValue}
                        isOpen={accordionTabOpened === 'All typologies'}
                        onOpen={() => onFilterToggle('All typologies')}
                        options={formOfSaleOptions}
                        label={formatMessage(BudgetMessages.allTypologies)}
                        onChange={handleFormOfSaleChange}
                        onClose={() => setAccordionTabOpened('')}
                    />
                    {/* <Typography className='copyright-mobile'>(c) Predictive modeling by DataDamus</Typography> */}
                </Drawer>
            }
        </div>
    )
}

export const FiltersBar: FC<Props> = (props) => {
    const isMobile = useIsMobile()
    const { locale: userLocale, user } = useUserContext()

    const properties = useMemo(() => {
        const properties = [...(props.properties || [])]

        if (user?.organizations && user.organizations.some((org) => org.name.toLowerCase().includes('grupo'))) {
            properties.push({
                ...grupoCheProperty,
                id: user?.organizations?.find(r => r.name.toLowerCase().includes('grupo'))?.id ?? ""
            })
        }

        return properties
    }, [props, user])

    const datesOptions = useMemo(() => {
        const datesOptions = [];

        for (let i = new Date().getMonth() - 6; i <= new Date().getMonth() + 5; i++) {
            const date = new Date()

            date.setDate(1)
            date.setMonth(i)

            datesOptions.push({
                value: format(date, 'yyyy-MM', { locale: getDateLocale(userLocale) }),
                label: upperFirst(format(date, 'LLLL yyyy', { locale: getDateLocale(userLocale) }))
            });
        }

        if (props.actualFilters.propertyName === 'Grupo Che') {
            const years = new Set(datesOptions.map(r => r.value.split('-')[0]))
            Array.from(years).forEach((year) => datesOptions.push({
                value: new Array(12).fill(null).map((_, i) => new Date(parseInt(year), i, 1).toISOString().slice(0, 7)).join(','),
                label: year
            }))
        }

        datesOptions.sort((a, b) => {
            const va = a.value.length > 7 ? a.value.slice(0, 4) : a.value;
            const vb = b.value.length > 7 ? b.value.slice(0, 4) : b.value;
            return va.localeCompare(vb)
        })

        return datesOptions;
    }, [props])

    const formOfSaleOptions = properties ? properties[0].typologies
        .map(t => ({
            value: t.alias,
            label: t.visualName,
            locale: getDateLocale(userLocale)
        })) : []

    const propertyNameOptions = useMemo(() => {
        if (!properties) {
            return []
        }

        return properties.map((property) => ({
            value: property.name,
            label: property.name,
        }))
    }, [properties])
   

    return isMobile ? (
        <MobileFilters { ...props } properties={properties} datesOptions={datesOptions} formOfSaleOptions={formOfSaleOptions} propertyNameOptions={propertyNameOptions} />
    ) : (
        <DesktopFilters { ...props } properties={properties} datesOptions={datesOptions} formOfSaleOptions={formOfSaleOptions} />
    )
}


type BreadCrumbsProps = {
    onFilterChange: (filters: { period: string; propertyName: string; formOfSale: 'dorm' | 'room' | 'global' | 'apart' }) => void
    actualFilters: { period: string; propertyName: string; formOfSale: 'dorm' | 'room' | 'global' | 'apart' }
    properties?: Property[]
}

export const FilterBreadcrumb: FC<BreadCrumbsProps> = (props) => {
    const {actualFilters, properties} = props

    const { propertyName, period, typologyName } = useMemo(() => {
        let currentProperty = properties?.find((property) => property.name === actualFilters.propertyName);
        let period = actualFilters.period;

        if (actualFilters.propertyName.toLowerCase().includes('grupo')) {
            currentProperty = grupoCheProperty
            period = actualFilters.period.length > 7 ? actualFilters.period.slice(0, 4) : actualFilters.period;
        }

        const typologyName = currentProperty?.typologies.find((typology) => typology.alias === actualFilters.formOfSale)?.visualName;

        return {
            propertyName: actualFilters.propertyName,
            period,
            typologyName
        }
    }, [actualFilters, properties])

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link href="#" color="inherit" underline="none">{propertyName}</Link>
        <Link href="#" color="inherit" underline="none">{period}</Link>
        <Link href="#" color="inherit" underline="none">{typologyName}</Link>
        </Breadcrumbs>
    );
};
