import React, { FC, useContext, CSSProperties, useState } from 'react'
import { AppBar, Avatar, Badge, Button, ButtonGroup, Icon, Menu, MenuItem } from '@mui/material'
import classnames from 'classnames'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import './TopBar.scss'
//Agrega Lucio
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { logInWithGoogle } from '../../services/firebase'
import { User } from 'firebase/auth'
// import { UserContext } from '../../App'
import UserContext from '../../contexts/UserContext'
import { useUserContext } from '../../utils/hooks'
import { FiltersBar } from '../screens/BudgetScreen/FiltersBar'
import { useIsMobile } from '../../utils/hooks'
import smallLogo from '../../assets/roitels-small-icon-mobile.svg'
import userIcon from '../../assets/usuario-icon-topbar.svg'
import { MenuMessages } from '../../lang/Messages'
import { useIntl } from 'react-intl'
import { logout } from '../../services/firebase'
import mxFlag from 'flagpack-core/svg/s/MX.svg'
import usFlag from 'flagpack-core/svg/s/US.svg'
import LoadingScreen from '../screens/BudgetScreen/LoadingScreen'
import { useNavigate } from 'react-router-dom'

type Props = {
    isSideBarOpen: boolean
    business: { name: string }
}

export const TopBar: FC<Props> = (props) => {
    const { isSideBarOpen } = props
    const {user, locale, setLocale, setLoading } = useContext(UserContext)
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = React.useState<null | HTMLElement>(null)
    const handleClose = () => setProfileMenuAnchorEl(null)
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setProfileMenuAnchorEl(event.currentTarget)
    }
    const handleLogin = () => {
        logInWithGoogle()
    }
    const isMobile = useIsMobile()
    const isProfileOpen = Boolean(profileMenuAnchorEl)

    const showLogoStyles: CSSProperties = {
        visibility: 'visible',
    }

    const hideLogoStyles: CSSProperties = {
        height: 0,
        width: 0,
        visibility: 'hidden',
    }

   const { formatMessage } = useIntl()
   
   const handleLocaleChange = (newLocale: string) => {
          setLoading(true)
          setTimeout(() => {
              setLocale(newLocale as 'en' | 'es')
              setLoading(false)
          }, 1500)
    }    

    const handleHelpCenterClick = () => {
        window.open('https://revenuemanagementmexico.com/', '_blank');
        handleClose();
    }

    const handleDownloadGuiaRapida = () => {
        if (locale === 'es') {
            window.open('https://www.roitels.com/wp-content/uploads/2023/05/Guia-Rapida.pdf', '_blank');
            handleClose();
        } else {
            window.open('https://www.roitels.com/wp-content/uploads/2023/05/Guia-Rapida.pdf', '_blank');
            handleClose();
        }
    }

    const handleBackToConsole = ()=>{
        localStorage.removeItem('idToken')
        window.location.href=`${process.env.REACT_APP_ROIBI_URI}`
    }

    const redirectToConfigScreen = ()=> {
        localStorage.removeItem('idToken')
        window.location.href=`${process.env.REACT_APP_ROIBI_CONFIG_SCREEN}`
    }

    return (
        <AppBar
            position="fixed"
            className={classnames('topbar', {
                isSideBarOpen,
                desktop: !isMobile,
            })}
        >
           
            <Toolbar className="toolbar">
                <div className="logoMobile">
                    <div style={!isMobile ? hideLogoStyles : showLogoStyles}>
                        <img className="smallLogo" src={smallLogo} onClick={handleBackToConsole} />
                    </div>
                </div>

                <div className="select-lenguage">
                    <FormControl variant="outlined" size="small">
                        <Select
                            value={locale}
                            onChange={(e) => handleLocaleChange(e.target.value)}
                            inputProps={{
                                name: 'language',
                                id: 'language-select',
                            }}
                        >
                            <MenuItem value={'en'}>English</MenuItem>
                            <MenuItem value={'es'}>Español</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <Menu
                    className="user-menu"
                    open={isProfileOpen}
                    onClose={handleClose}
                    anchorEl={profileMenuAnchorEl}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    PaperProps={{
                        sx: {
                            width: '180px',
                        },
                    }}
                >
                    <MenuItem onClick={redirectToConfigScreen}>Configuración</MenuItem>
                    <MenuItem onClick={handleBackToConsole}>Cerrar sesión</MenuItem>
                    {/* <MenuItem onClick={handleClose}>{formatMessage(MenuMessages.menuAccount)}</MenuItem>
                    <MenuItem onClick={handleClose}>{formatMessage(MenuMessages.menuSubscriptions)} </MenuItem>
                    <MenuItem onClick={handleClose}>{formatMessage(MenuMessages.menuContact)} </MenuItem> 
                    <MenuItem onClick={handleDownloadGuiaRapida}>{formatMessage(MenuMessages.menuContact)} </MenuItem>*/}
                    {/* <MenuItem onClick={handleHelpCenterClick}>{formatMessage(MenuMessages.menuHelpCenter)} </MenuItem> */}
                    {/* <MenuItem onClick={logout}>{formatMessage(MenuMessages.menuLogout)}</MenuItem> */}
                    <div className="select-lenguage-mobile">
                        <ButtonGroup variant="text">
                            <Button
                                onClick={() => {
                                    handleLocaleChange('en')
                                    handleClose()
                                }}
                            >
                                English
                            </Button>
                            <Button
                                onClick={() => {
                                    handleLocaleChange('es')
                                    handleClose()
                                }}
                            >
                                Español
                            </Button>
                        </ButtonGroup>
                    </div>
                </Menu>

                {user ? (
                    <Button className="avatar-button fixed-to-right" onClick={handleOpen}>
                        <Avatar src={user?.photoURL || userIcon} />
                    </Button>
                ) : (
                    <Button className="avatar-button" onClick={handleLogin}>
                        <Avatar src={userIcon} />
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    )
}
