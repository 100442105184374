import {ReactNode} from 'react';
import {Roi360Icon, RoiDsIcon, RoiMlIcon, RoiReviewIcon} from '../assets/Icons';

type RoutesInfo = {
    id: string
    sectionTitle: string
    Icon: ReactNode
    elements: {
        title: string
        path: string
    }[]
}

export const ROUTES_LINKS = {
    view360: '360-view',
    smartView: 'smart-view',
    dashboard: 'dashboard',
    revenue: 'revenue',
    occupancy: 'occupancy',
    adr: 'adr',
    revPau: 'rev-pau',
    budget: 'budget',
}

const ROUTES: RoutesInfo[] = [
    {
        id: 'roi360',
        sectionTitle: 'Roi 360°',
        Icon: <Roi360Icon />,
        elements: [
            {
                title: 'Dashboard',
                path: ROUTES_LINKS.dashboard,
            },
            {
                title: 'Revenue',
                path: ROUTES_LINKS.revenue,
            },
            {
                title: 'Occupancy',
                path: ROUTES_LINKS.occupancy,
            },
            {
                title: 'ADR',
                path: ROUTES_LINKS.adr,
            }
        ],
    },
    {
        id: 'roiDS',
        sectionTitle: 'RoiDS',
        Icon: <RoiDsIcon />,
        elements: [
            {
                title: 'Channels over view',
                path: 'k',
            },
            {
                title: 'Direct sales',
                path: 'j',
            },
            {
                title: "OTA's Sales",
                path: 'i',
            },
        ],
    },
    {
        id: 'roiReveniew',
        sectionTitle: 'RoiReview',
        Icon: <RoiReviewIcon />,
        elements: [
            {
                title: 'Review overview',
                path: 'h',
            },
            {
                title: 'Score up',
                path: 'g',
            },
            {
                title: `OTA's sales`,
                path: 'f',
            },
        ],
    },
    {
        id: 'RoiML',
        sectionTitle: 'RoiML',
        Icon: <RoiMlIcon />,
        elements: [
            {
                title: 'Dynamic CompSet',
                path: 'e',
            },
            {
                title: 'Smart Calendar',
                path: 'd',
            },
            {
                title: 'Smart forecast',
                path: 'c',
            },
            {
                title: 'Smart Pick up',
                path: 'b',
            },
            {
                title: 'Smart Price',
                path: 'a',
            },
        ],
    },
]

export default ROUTES
