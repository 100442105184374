import { FC } from 'react';
import './CardValues.scss'
import Typography from '@mui/material/Typography';
import {Card, CardContent } from '@mui/material';
import { RoiMlIcon } from '../../../assets/Icons';
import InfoIcon from '@mui/icons-material/Info';

type CardProps = {
    values: { color: string, title: string, value: number }[],
    title: string
}

const CardValues: FC<CardProps> = (props) => {
    const { title, values } = props
    return (
        <Card className='card-values-dashboard'>
            <div className='titles-container'>
                <Typography color="text.secondary" gutterBottom className='card-title'>
                    <RoiMlIcon /> {title}
                </Typography>

                <Typography className='dynamic-compset-title'>
                    Todays prices - Dynamic compset
                </Typography>

                <InfoIcon/>
            </div>

            <div className='values-container'>
                {values.map(({
                    color,
                    title,
                    value
                }) => (
                    <CardContent
                        key={`box_${title}`}
                        sx={{
                            p: 1,
                            textAlign: 'center'
                        }}
                    >
                        <Typography
                            key={`typ_${title}`}
                            style={{ color }}
                            variant="h4"
                        >
                            ${value}
                        </Typography>
                        <Typography
                            color="text.secondary"
                            variant="body1"
                        >
                            {title}
                        </Typography>
                    </CardContent>
                ))}
            </div>
        </Card>
    )
}

export default CardValues
