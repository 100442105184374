import { createContext } from 'react'
import BackendServices from '../services/BackendServices'

const UserContext = createContext<{
    user: UserInfo | undefined
    userLoading: boolean
    backendServices?: BackendServices
    locale: 'en' | 'es'
    setLocale: (locale: 'en' | 'es') => void
    loading: boolean
    setLoading: (loading: boolean) => void

}>({
    user: undefined,
    userLoading: true,
    backendServices: undefined,
    locale: 'en',
    setLocale: (locale) => {
        console.log(`Cambio de locale a ${locale}`)
    },
    loading: false,
    setLoading: (loading) => {
      console.log(`Cambio de locale a ${loading}`)
    },
})

export default UserContext
