import { useNavigate } from 'react-router-dom'
import { FC, useContext } from 'react'
import { UserContext } from '../../App'

export { PrivateRoute }

type Props = {
    element: JSX.Element
}
const PrivateRoute: FC<Props> = (props) => {
    const { element } = props
    const navigate = useNavigate()
    const { userLoading, user } = useContext(UserContext)

    if (userLoading) return null
    // if (!user) {
    //     navigate('/login')
    // }

    return element
}

export default PrivateRoute
