import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import UserContext from '../contexts/UserContext'

export function useHover() {
    const [value, setValue] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    const handleMouseOver = () => setValue(true)
    const handleMouseOut = () => setValue(false)
    useEffect(
        () => {
            const node = ref.current
            if (node) {
                node.addEventListener('mouseover', handleMouseOver)
                node.addEventListener('mouseout', handleMouseOut)
                return () => {
                    node.removeEventListener('mouseover', handleMouseOver)
                    node.removeEventListener('mouseout', handleMouseOut)
                }
            }
        },
        [ref.current], // Recall only if ref changes
    )
    return { ref, value }
}

export const useIsMobile = () => {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
}

export const useIsTablet = () => {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.between('sm', 'lg'), { noSsr: true })
}

export const useIsDesktop = () => {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true })
}

export const useScreenBrakpoints = () => {
    const theme = useTheme()
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
    const isUpLg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true })
    const isBetweenSmLg = useMediaQuery(theme.breakpoints.between('sm', 'lg'), { noSsr: true })
    const isUpXl = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true })
    return { isUpLg, isUpXl, isDownSm, isBetweenSmLg }
}

export const useToggle = (initialState = false) => {
    // Initialize the state
    const [state, setState] = useState(initialState)

    // Define and memorize toggler function in case we pass down the component,
    // This function change the boolean value to it's opposite value
    const toggle = useCallback(() => setState((state) => !state), [])
    const setFalse = () => setState(false)
    const setTrue = () => setState(true)

    return { state, toggle, setFalse, setTrue }
}

export const useUserContext = () => {
    return useContext(UserContext)
}
