import React, { CSSProperties, FC, useState } from 'react'
import Drawer from '@mui/material/Drawer'
import classnames from 'classnames'
import IconButton from '@mui/material/IconButton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ROUTES from '../../constants/ROUTES'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { Icon, Select } from '@mui/material'
import { styled } from '@mui/material/styles'
import './SideBar.scss'
import smallLogo from '../../assets/roitels-small-icon.svg'
import bigLogo from '../../assets/roitels-big-icon.svg'
import { Link, useLocation } from 'react-router-dom'
//Import (agrega Lucio)
import CopyrightIcon from '@mui/icons-material/Copyright'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { NativeSelect, FormControl, InputLabel } from '@mui/material'


type SideBarProps = {
    onToggleOpen: () => void
    open: boolean
    business: { name: string }
    location?: Location
}

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1), // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

export const SideBar: FC<SideBarProps> = (props) => {
    const { onToggleOpen: onToggleSideBarOpen, open, business } = props
    const [openSections, setOpenSections] = useState<Record<string, boolean>>(
        {},
    )
    const location = useLocation()

    const handleOpenSection = (id: string) => {
        setOpenSections((state) => ({ ...state, [id]: !state[id] }))
    }

    const showLogoStyles: CSSProperties = {
        visibility: 'visible',
    }

    const hideLogoStyles: CSSProperties = {
        height: 0,
        width: 0,
        visibility: 'hidden',
    }

    const handleBackToConsole = ()=>{
        localStorage.removeItem('idToken')
        window.location.href=`${process.env.REACT_APP_ROIBI_URI}`
    }

    return (
        <>
            <Drawer
                variant="permanent"
                open={open}
                className={classnames('side-bar', {
                    open: open,
                })}
            >
                <Divider />
                <DrawerHeader className='logo-container'>
                    <div>
                        <div style={!open ? showLogoStyles : hideLogoStyles}>
                            <img className="smallLogo" src={smallLogo} style={{cursor: 'pointer'}} onClick={handleBackToConsole}/>
                        </div>

                        <div style={open ? showLogoStyles : hideLogoStyles}>
                            <img className="logo" src={bigLogo} />
                        </div>
                    </div>
                </DrawerHeader>


                <List className="sidebar-list">
                    {open && (
                        <>
                            <Typography
                                variant="h6"
                                noWrap
                                component="h6"
                                className="userName"
                            >
                                {business?.name}
                            </Typography>
                            <Link className="item-link" to={'/budget'}>
                                <Button className="budget">Budgets</Button>
                            </Link>
                        </>
                    )}

                    {open && (
                        <Box className="property-selector">
                            <FormControl fullWidth>
                                <InputLabel
                                    className="labelSelector"
                                    htmlFor="uncontrolled-native"
                                >
                                    Select Your Property
                                </InputLabel>
                                <Select
                                    className="optionSelector"
                                    defaultValue={10}
                                    inputProps={{
                                        name: 'age',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={10}>Default Property</option>
                                    {/* <option value={20}>Second Property</option>
                                    <option value={30}>Third Property</option> */}
                                </Select>
                            </FormControl>
                        </Box>
                    )}

                    {/*{ROUTES.map((section) => {*/}
                    {/*    const isOpen = openSections?.[section.id]*/}
                    {/*    const isActive = section.elements.some(*/}
                    {/*        (elem) => location.pathname === '/' + elem.path,*/}
                    {/*    )*/}
                    {/*    return open ? (*/}
                    {/*        <div*/}
                    {/*            className={classnames('list-section', {*/}
                    {/*                active: isActive,*/}
                    {/*            })}*/}
                    {/*        >*/}
                    {/*            <ListItemButton*/}
                    {/*                onClick={() =>*/}
                    {/*                    handleOpenSection(section.id)*/}
                    {/*                }*/}
                    {/*            >*/}
                    {/*                <ListItemIcon className={'section-icon'}>*/}
                    {/*                    {section.Icon}                                        */}
                    {/*                </ListItemIcon>*/}
                    {/*                <ListItemText*/}
                    {/*                    primary={section.sectionTitle}*/}
                    {/*                />*/}
                    {/*                {isOpen ? <ExpandLess /> : <ExpandMore />}*/}
                    {/*            </ListItemButton>*/}
                    {/*            {isOpen &&*/}
                    {/*                section.elements.map((route) => (*/}
                    {/*                    <ListItem*/}
                    {/*                        key={route.title + '-' + route.path}*/}
                    {/*                        disablePadding*/}
                    {/*                        sx={{ display: 'block' }}*/}
                    {/*                    >*/}
                    {/*                        <Link*/}
                    {/*                            className={classnames(*/}
                    {/*                                'item-link',*/}
                    {/*                                {*/}
                    {/*                                    active:*/}
                    {/*                                        location.pathname ===*/}
                    {/*                                        '/' + route.path,*/}
                    {/*                                },*/}
                    {/*                            )}*/}
                    {/*                            to={route.path}*/}
                    {/*                        >*/}
                    {/*                            <ListItemButton*/}
                    {/*                                sx={{*/}
                    {/*                                    minHeight: 48,*/}
                    {/*                                    justifyContent: open*/}
                    {/*                                        ? 'initial'*/}
                    {/*                                        : 'center',*/}
                    {/*                                    px: 2.5,*/}
                    {/*                                }}*/}
                    {/*                            >*/}
                    {/*                                <ListItemText*/}
                    {/*                                    primary={route.title}*/}
                    {/*                                    sx={{*/}
                    {/*                                        opacity: open*/}
                    {/*                                            ? 1*/}
                    {/*                                            : 0,*/}
                    {/*                                    }}*/}
                    {/*                                />*/}
                    {/*                            </ListItemButton>*/}
                    {/*                        </Link>*/}
                    {/*                    </ListItem>*/}
                    {/*                ))}*/}
                    {/*        </div>*/}
                    {/*    ) : (*/}
                    {/*        <ListItemButton>*/}
                    {/*            <Icon className="sidebar-button">*/}
                    {/*                {section.Icon}*/}
                    {/*            </Icon>*/}
                    {/*        </ListItemButton>*/}
                    {/*    )*/}
                    {/*})}*/}

                    {open && (
                        <Stack spacing={0.5} className="drawerFooter">
                            {/* <Button variant="text" className="help-center">
                                <HelpCenterIcon /> Help Center
                            </Button> */}
                            <Button variant="text" className="copyRight">
                                {' '}
                                <CopyrightIcon sx={{ fontSize: 15 }} /> AI
                                Revenue 22.04.02
                            </Button>
                        </Stack>
                    )}
                </List>
            </Drawer>

            {/*<IconButton*/}
            {/*    className={classnames('sidebar-icon', {*/}
            {/*        open: open,*/}
            {/*    })}*/}
            {/*    onClick={onToggleSideBarOpen}*/}
            {/*>*/}
            {/*    {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}*/}
            {/*</IconButton>*/}
        </>
    )
}
