import React, { FC } from 'react'
import { SvgIcon } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon'

const Roi360Icon: FC = () => {
    return (
        <SvgIcon
            height="512pt"
            viewBox="0 -66 512.001 512"
            width="512pt"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m322.285156 335.644531c-7.441406 0-13.898437-5.53125-14.863281-13.105469-1.042969-8.21875 4.769531-15.726562 12.984375-16.773437 47.398438-6.039063 89.84375-18.882813 119.515625-36.171875 27.136719-15.808594 42.078125-34.394531 42.078125-52.332031 0-19.769531-17.484375-35.945313-32.15625-46.039063-6.824219-4.695312-8.550781-14.03125-3.855469-20.859375 4.695313-6.824219 14.035157-8.550781 20.859375-3.855469 29.539063 20.320313 45.152344 44.785157 45.152344 70.757813 0 29.476563-19.699219 56.535156-56.972656 78.25-33.550782 19.546875-78.789063 33.382813-130.828125 40.011719-.644531.078125-1.285157.117187-1.914063.117187zm0 0" />
            <path d="m252.34375 314.15625-40-40c-5.859375-5.859375-15.355469-5.859375-21.214844 0-5.855468 5.855469-5.855468 15.355469 0 21.210938l11.6875 11.6875c-44.8125-4.628907-85.523437-15.0625-117.046875-30.222657-35.441406-17.042969-55.769531-38.757812-55.769531-59.570312 0-17.652344 14.554688-36 40.980469-51.664063 7.128906-4.222656 9.480469-13.425781 5.257812-20.550781-4.226562-7.128906-13.429687-9.480469-20.554687-5.257813-46.023438 27.28125-55.683594 57.1875-55.683594 77.472657 0 33.28125 25.84375 64.039062 72.769531 86.609375 36.421875 17.511718 83.535157 29.242187 134.863281 33.78125l-16.503906 16.503906c-5.855468 5.855469-5.855468 15.355469 0 21.214844 2.929688 2.925781 6.769532 4.390625 10.609375 4.390625 3.835938 0 7.675781-1.464844 10.605469-4.390625l40-40c5.855469-5.859375 5.855469-15.359375 0-21.214844zm0 0" />
            <path d="m157.097656 187.222656v-3.609375c0-12.730469-7.792968-15.199219-18.242187-15.199219-6.460938 0-8.550781-5.699218-8.550781-11.398437 0-5.703125 2.089843-11.402344 8.550781-11.402344 7.21875 0 14.820312-.949219 14.820312-16.339843 0-11.019532-6.269531-13.679688-14.0625-13.679688-9.308593 0-14.058593 2.28125-14.058593 9.691406 0 6.457032-2.851563 10.828125-13.871094 10.828125-13.679688 0-15.386719-2.851562-15.386719-11.972656 0-14.816406 10.636719-34.007813 43.316406-34.007813 24.132813 0 42.371094 8.738282 42.371094 34.390626 0 13.867187-5.128906 26.789062-14.628906 31.160156 11.210937 4.179687 19.378906 12.539062 19.378906 27.929687v3.609375c0 31.160156-21.46875 42.941406-48.070313 42.941406-32.679687 0-45.21875-19.949218-45.21875-35.910156 0-8.550781 3.609376-10.832031 14.058594-10.832031 12.160156 0 15.199219 2.660156 15.199219 9.882813 0 8.929687 8.363281 11.019531 16.910156 11.019531 12.921875 0 17.484375-4.75 17.484375-17.101563zm0 0" />
            <path d="m302.066406 183.613281v1.710938c0 32.679687-20.332031 44.839843-46.550781 44.839843s-46.742187-12.160156-46.742187-44.839843v-50.351563c0-32.679687 21.089843-44.839844 48.453124-44.839844 32.109376 0 44.839844 19.949219 44.839844 35.71875 0 9.121094-4.371094 11.96875-13.871094 11.96875-8.167968 0-15.390624-2.089843-15.390624-10.828124 0-7.21875-7.597657-11.019532-16.527344-11.019532-11.210938 0-17.863282 5.890625-17.863282 19v17.097656c6.082032-6.648437 14.632813-8.359374 23.753907-8.359374 21.65625 0 39.898437 9.5 39.898437 39.902343zm-63.652344 3.800781c0 13.109376 6.460938 18.808594 17.101563 18.808594s16.910156-5.699218 16.910156-18.808594v-1.710937c0-13.871094-6.269531-19.191406-17.101562-19.191406-10.257813 0-16.910157 4.941406-16.910157 17.480469zm0 0" />
            <path d="m325.054688 185.324219v-50.351563c0-32.679687 20.328124-44.839844 46.550781-44.839844 26.21875 0 46.738281 12.160157 46.738281 44.839844v50.351563c0 32.679687-20.519531 44.839843-46.738281 44.839843-26.222657 0-46.550781-12.160156-46.550781-44.839843zm63.648437-50.351563c0-13.109375-6.457031-19-17.097656-19s-16.910157 5.890625-16.910157 19v50.351563c0 13.109375 6.269532 19 16.910157 19s17.097656-5.890625 17.097656-19zm0 0" />
            <path d="m454.351562 90c-24.816406 0-45-20.1875-45-45s20.183594-45 45-45c24.8125 0 45 20.1875 45 45s-20.1875 45-45 45zm0-60c-8.273437 0-15 6.730469-15 15 0 8.273438 6.726563 15 15 15 8.269532 0 15-6.726562 15-15 0-8.269531-6.730468-15-15-15zm0 0" />
        </SvgIcon>
    )
}

const RoiDsIcon: FC = () => {
    return (
        <SvgIcon
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <path
                className="st0"
                d="M497,482h-15V152h15c5.4,0,10.4-2.9,13.1-7.7s2.5-10.5-0.3-15.2l-75-122C432.1,2.7,427.2,0,422,0
	s-10.1,2.7-12.8,7.1l-75,122c-2.8,4.6-3,10.4-0.3,15.2c2.7,4.7,7.7,7.7,13.1,7.7h15v330h-30V227c0-8.3-6.7-15-15-15h-90
	c-8.3,0-15,6.7-15,15v255h-30V317c0-8.3-6.7-15-15-15H77c-8.3,0-15,6.7-15,15v165H15c-8.3,0-15,6.7-15,15s6.7,15,15,15h482
	c8.3,0,15-6.7,15-15S505.3,482,497,482z M242,242h60v240h-60V242z M92,332h60v150H92V332z M392,482V137c0-8.3-6.7-15-15-15h-3.2
	L422,43.6l48.2,78.4H467c-8.3,0-15,6.7-15,15v345H392z"
            />
            <path
                className="st0"
                d="M45,122c8.3,0,15,6.7,15,15s-6.7,15-15,15c-5.3,0-10.3-2.9-13-7.5c-4.1-7.2-13.3-9.6-20.5-5.5
	S1.9,152.3,6,159.5c5.4,9.4,14,16.4,24,19.9v16.4c0,8.3,6.7,15,15,15s15-6.7,15-15v-16.4c17.5-6.2,30-22.9,30-42.4
	c0-24.8-20.2-45-45-45c-8.3,0-15-6.7-15-15s6.7-15,15-15c5.3,0,10.3,2.9,13,7.5c4.1,7.2,13.3,9.6,20.5,5.5s9.6-13.3,5.5-20.5
	c-5.4-9.4-14-16.4-24-19.9V15c0-8.3-6.7-15-15-15S30,6.7,30,15v19.6C12.5,40.8,0,57.4,0,77C0,101.8,20.2,122,45,122z"
            />
        </SvgIcon>
    )
}

const RoiMlIcon: FC = (props) => {
    return (
        <SvgIcon
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <g>
                <path
                    className="st0"
                    d="M254.9,124.8c-74.7,0-134.4,60.8-134.4,134.4c0,74.7,60.8,135.5,134.4,135.5s134.4-60.8,134.4-135.5
		C390.4,184.5,329.6,124.8,254.9,124.8L254.9,124.8z M326.4,269.9h-17.1c-1.1,7.5-4.3,13.9-8.5,20.3l12.8,12.8l-14.9,13.9L285.9,304
		c-5.3,4.3-12.8,6.4-20.3,8.5v18.1h-21.3v-17.1c-7.5-1.1-13.9-4.3-20.3-8.5l-12.8,12.8l-14.9-14.9l12.8-12.8
		c-4.3-5.3-6.4-12.8-8.5-20.3h-17.1v-21.3h17.1c1.1-7.5,4.3-13.9,8.5-20.3l-12.8-12.8l14.9-14.9l12.8,12.8
		c6.4-4.3,12.8-6.4,20.3-8.5v-17.1h21.3v17.1c7.5,1.1,13.9,4.3,20.3,8.5l12.8-12.8l14.9,14.9l-12.8,12.8c4.3,6.4,6.4,12.8,8.5,20.3
		h17.1V269.9z"
                />
                <path
                    className="st0"
                    d="M289.1,259.2c0,19.2-14.9,34.1-34.1,34.1c-18.1,0-34.1-14.9-34.1-34.1c0-18.1,14.9-34.1,34.1-34.1
		S289.1,241.1,289.1,259.2z"
                />
                <path
                    className="st0"
                    d="M513.1,376.5c0,23.5-19.2,42.7-42.7,42.7c-20.3,0-36.3-13.9-41.6-32h-33.1l-28.8-28.8l14.9-14.9l22.4,22.4
		h23.5c4.3-18.1,21.3-32,41.6-32C493.9,333.9,513.1,353.1,513.1,376.5L513.1,376.5z"
                />
                <path
                    className="st0"
                    d="M381.9,176L367,161.1l28.8-28.8h33.1c4.3-18.1,21.3-32,41.6-32c23.5,0,42.7,19.2,42.7,42.7
		s-19.2,42.7-42.7,42.7c-20.3,0-36.3-13.9-41.6-32h-23.5L381.9,176z"
                />
                <path
                    className="st0"
                    d="M513.1,259.2c0,23.5-19.2,42.7-42.7,42.7c-20.3,0-36.3-13.9-41.6-32h-27.7v-21.3h27.7
		c4.3-18.1,21.3-32,41.6-32C493.9,216.5,513.1,235.7,513.1,259.2z"
                />
                <path
                    className="st0"
                    d="M-1.1,141.9c0-23.5,19.2-42.7,42.7-42.7c20.3,0,36.3,13.9,41.6,32h32L144,160l-14.9,14.9l-22.4-22.4H83.2
		c-5.3,18.1-21.3,32-41.6,32C18.1,184.5-1.1,165.3-1.1,141.9L-1.1,141.9z"
                />
                <path
                    className="st0"
                    d="M-1.1,259.2c0-23.5,19.2-42.7,42.7-42.7c20.3,0,36.3,13.9,41.6,32h27.7v21.3H83.2c-5.3,18.1-21.3,32-41.6,32
		C18.1,301.9-1.1,282.7-1.1,259.2z"
                />
                <path
                    className="st0"
                    d="M130.1,342.4l14.9,14.9l-28.8,28.8h-32c-2.1,7.5-5.3,13.9-10.7,19.2c-9.6,9.6-20.3,13.9-32,13.9
		C18,419.2-1.2,400-1.2,376.5s19.2-42.7,42.7-42.7c20.3,0,36.3,13.9,41.6,32h23.5L130.1,342.4z"
                />
            </g>
        </SvgIcon>
    )
}

const RoiReviewIcon: FC = () => {
    return (
        <SvgIcon viewBox="0 0 32 32">
            <g id="_x31_4_comment">
                <g>
                    <path d="m8 30c-.147 0-.295-.033-.434-.099-.345-.167-.566-.516-.566-.901v-5h-3c-1.654 0-3-1.346-3-3v-16c0-1.654 1.346-3 3-3h24c1.654 0 3 1.346 3 3v16c0 1.654-1.346 3-3 3h-12.149l-7.226 5.781c-.181.145-.402.219-.625.219zm-4-26c-.552 0-1 .449-1 1v16c0 .551.448 1 1 1h4c.553 0 1 .447 1 1v3.92l5.875-4.701c.178-.142.397-.219.625-.219h12.5c.552 0 1-.449 1-1v-16c0-.551-.448-1-1-1z" />
                </g>
                <g>
                    <path d="m24 12h-16c-.553 0-1-.448-1-1s.447-1 1-1h16c.553 0 1 .448 1 1s-.447 1-1 1z" />
                </g>
                <g>
                    <path d="m16 16h-8c-.553 0-1-.448-1-1s.447-1 1-1h8c.553 0 1 .448 1 1s-.447 1-1 1z" />
                </g>
            </g>
        </SvgIcon>
    )
}

const HelpCenterIcon: FC = () => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <g>
                <g>
                    <path
                        className="st0"
                        d="M256,0C158,0,78.3,79.7,78.3,177.7c0,65.2,36.2,125.3,93.1,156.4v36.7c0,10.2,8.3,18.5,18.5,18.5h131.4    c10.2,0,18.5-8.3,18.5-18.5v-36.7c57.3-30.9,93.8-91.1,93.8-156.4C433.7,79.7,354,0,256,0z M313.7,305.8    c-6.6,3-10.8,9.6-10.8,16.8v29.6h-94.4v-29.6c0-7.2-4.2-13.8-10.8-16.8c-50-23.1-82.4-73.4-82.4-128.2    C115.4,100.1,178.4,37,256,37s140.6,63.1,140.6,140.6C396.6,232.5,364.1,282.8,313.7,305.8z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M307.2,413.9H204.8c-10.2,0-18.5,8.3-18.5,18.5s8.3,18.5,18.5,18.5h102.4c10.2,0,18.5-8.3,18.5-18.5    C325.7,422.2,317.4,413.9,307.2,413.9z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        d="M277.6,475h-43.2c-10.2,0-18.5,8.3-18.5,18.5s8.3,18.5,18.5,18.5h43.2c10.2,0,18.5-8.3,18.5-18.5    C296.1,483.3,287.8,475,277.6,475z"
                    />
                </g>
            </g>
        </SvgIcon>
    )
}

export { Roi360Icon, RoiReviewIcon, RoiDsIcon, RoiMlIcon, HelpCenterIcon }
