import axios, { Axios } from 'axios'

class BackendServices {
    private instance: Axios

    constructor(token: string) {
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_BACKEND_URL,
            timeout: 30000,
            headers: { Authorization: `Bearer ${token}` },
        })
    }

    getUserInfo = async () => {
        return this.instance.get('/user/info')
    }

    getBudgetScreen = async (filters?: BudgetQuery) => {
        const response = await this.instance.get("/budget", {params: filters}) 
        if (!filters) {
            return await this.instance.get('/budget')
        }
        return response
    }

    getBudgetOrganizationScreen = async (filters?: BudgetOrganizationQuery) => {
        const response = await this.instance.get("/budget/organizations", {params: filters}) 
        if (!filters) {
            return await this.instance.get('/budget')
        }
        return response
    }

    getRoot = async () => {
        return await this.instance.get('/', { headers: { 'Content-type': 'text/plain' } })
    }

    sendCloudBedsCodeBackend = async (code: string | null) => {
        if (!code) return;

        const response = await this.instance.get(
            `${process.env.REACT_APP_BACKEND_URL}/redirect/roibi?code=${code}`
        );
        
        if (response.status === 200) {
            window.location.href = `${process.env.REACT_APP_BACKEND_URL}/login/success`
        }
    }

    setIdToken = (token: string)=> {
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_BACKEND_URL,
            timeout: 8000,
            headers: { Authorization: `Bearer ${token}` },
        })
    }
}

export default BackendServices
