import {initializeApp} from 'firebase/app';
import {getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, User} from 'firebase/auth';
import {CompleteFn, ErrorFn} from '@firebase/util';
import {NextOrObserver} from '@firebase/auth';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)
const provider = new GoogleAuthProvider()

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app)
//Logout

export const logout = async () => {
    await auth.signOut().then(() => {
        window.location.reload()
    })
}

//Logout

export const loginWithUserAndPass = ({ email, password }: { email: string; password: string }) => {
    const promise = new Promise((resolve, reject) => {
        signInWithEmailAndPassword(auth, email, password)
            .then((result) => {
                console.log(result)
                resolve(true)
            })
            .catch(() => {
                reject(false)
            })
    })
    return promise
}
export const logInWithGoogle = () => {
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result)
            if (!credential) {
                console.error('fail to get credential', result)
                return
            }
            const token = credential.accessToken
            const user = result.user
        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code
            const errorMessage = error.message
            // The email of the user's account used.
            const email = error.customData.email
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error)
            console.error('fail to sign in', error, credential, email, errorCode, errorMessage)
        })
}
