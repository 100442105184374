import { TopBar } from './TopBar'
import { SideBar } from './SideBar'
import classnames from 'classnames'
import React, { FC, useContext, useState } from 'react'
import mockData from '../../constants/mock'
import { UserContext } from '../../App'
import { useIsMobile } from '../../utils/hooks'

type Props = {
    children?: JSX.Element
}

const NavigationContainer: FC<Props> = (props) => {
    const { user, userLoading } = useContext(UserContext)
    const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false)
    // console.log(userLoading, user)
    const { business } = mockData
    const isMobile = useIsMobile();
    const onSideBarOpenToggle = () => {
        setIsSideBarOpen((state) => !state)
    }
    const { children } = props
    if (!children) return null

    if (userLoading || !user) return children

    return (
        <>
            <TopBar isSideBarOpen={isSideBarOpen} business={business} />
            {!isMobile && <SideBar onToggleOpen={onSideBarOpenToggle} open={isSideBarOpen} business={business} />}
            <div
                className={classnames('main-content', {
                    'open-sidebar': isSideBarOpen,
                    'mobile': isMobile,
                    'desktop': !isMobile
                })}
            >
                {children}
            </div>
        </>
    )
}

export default NavigationContainer
