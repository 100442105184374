import { FC, useContext, useEffect, useState } from 'react'
import './BudgetScreen.scss'
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Modal, Radio, RadioGroup, Typography } from '@mui/material'
import { FilterBreadcrumb, FiltersBar } from './FiltersBar'
import WorldMap from './WorldMap'
import classnames from 'classnames'
import { useIsDesktop, useIsMobile } from '../../../utils/hooks'
import BudgetCards from './BudgetCards'
import { Alpha2Code } from 'i18n-iso-countries'
import { useIntl } from 'react-intl'
import { BudgetMessages } from '../../../lang/Messages'
import { UserContext } from '../../../App'
import { format } from 'date-fns'
import LoadingScreen from './LoadingScreen'
import alpha2ToAlpha3 from 'iso-3166-1'
import CountriesDataTable from './CountriesDataTable'
import CloseIcon from '@mui/icons-material/Close'
import SkeletonForCountryTable from '../../commons/Skeletons/SkeletonsForCountryTables'

type Props = {
    open?: boolean
    userInfo?: UserInfo
}

const periodTypes: ChartPeriod[] = ['Budget', 'Now', 'ToGo', 'LastYear']

const countryRevenue: { country: Alpha2Code; value: number }[] = []

function ParserCountryTable(metric: string, budgetData?: BudgetData) {
    const RevenueTableMetric = budgetData?.lastAnalysis.find((ob) => ob.orderBy === metric)
    if (!RevenueTableMetric) return countryRevenue

    return RevenueTableMetric.analysis.map((ca) => {
        return {
            country: ca.country,
            value: ca.analysis.metrics[RevenueTableMetric.orderBy].now,
        }
    })
}

const allWorldMapDataTypes = ['Revenue', 'Occupancy']

const BudgetScreen: FC<Props> = (props) => {
    const [showNoDataModal, setShowNoDataModal] = useState(false)
    const [budgetDataLoaded, setBudgetDataLoaded] = useState(false);
    const [worldMapDataType, setWorldMapDataType] = useState<ChartTitle>('Revenue')
    const [budgetData, setBudgetData] = useState<BudgetData | undefined>(undefined)
    const { backendServices, loading } = useContext(UserContext)
    const [actualFilters, setActualFilters] = useState<{ propertyName: string; formOfSale: 'dorm' | 'room' | 'global' | 'apart'; period: string }>({
        propertyName: '',
        formOfSale: 'global',
        period: format(new Date(), 'yyyy-MM'),
    })
    const { userInfo } = props
    const [checkboxFilters, setCheckboxFilters] = useState<Record<ChartPeriod, boolean>>({
        Budget: true,
        Now: true,
        ToGo: true,
        LastYear: true,
    })
    const lastAnalysisMetrics: BudgetMetrics = budgetData?.lastTotalAnalysis.analysis.metrics as BudgetMetrics
    const properties = userInfo?.properties

    const data: Record<
        ChartTitle,
        {
            Budget: number
            Now: number
            ToGo?: number
            LastYear: number
        }
    > = lastAnalysisMetrics && {
        Revenue: {
            Budget: lastAnalysisMetrics.revenue.budget,
            Now: lastAnalysisMetrics.revenue.now,
            ToGo: lastAnalysisMetrics.revenue.toGo,
            LastYear: lastAnalysisMetrics.revenue.lastYear,
        },
        Occupancy: {
            Budget: lastAnalysisMetrics.occupancy.budget,
            Now: lastAnalysisMetrics.occupancy.now,
            ToGo: lastAnalysisMetrics.occupancy.toGo,
            LastYear: lastAnalysisMetrics.occupancy.lastYear,
        },
        RevPAR: {
            Budget: lastAnalysisMetrics.revPar.budget,
            Now: lastAnalysisMetrics.revPar.now,
            ToGo: lastAnalysisMetrics.revPar.toGo,
            LastYear: lastAnalysisMetrics.revPar.lastYear,
        },
        ADR: {
            Budget: lastAnalysisMetrics.adr.budget,
            Now: lastAnalysisMetrics.adr.now,
            ToGo: lastAnalysisMetrics.adr.toGo,
            LastYear: lastAnalysisMetrics.adr.lastYear,
        },
    }
    const { formatMessage } = useIntl()
    const isDesktop = useIsDesktop()
    const isMobile = useIsMobile()

    const toggleCheckbox = (period: ChartPeriod) => {
        setCheckboxFilters((prevState) => ({
            ...prevState,
            [period]: !prevState[period],
        }))
    }

    const convertRevenueTableAlpha3 = (metric: string, budgetData?: BudgetData) =>
        ParserCountryTable(metric, budgetData)?.map((item) => {
            return {
                country: alpha2ToAlpha3.whereAlpha2(item.country)?.alpha3 || item.country,
                value: item.value,
            }
        })

    const getSelectedDataForWorldMapDataType = (): { country: Alpha2Code; value: number }[] => {
        if (worldMapDataType === 'Revenue') {
            return convertRevenueTableAlpha3('revenue', budgetData) || []
        }

        if (worldMapDataType === 'Occupancy') {
            return convertRevenueTableAlpha3('occupancy', budgetData) || []
        }

        return []
    }

    const ocuppancyToWorldMapData = getSelectedDataForWorldMapDataType()    

    const getPropertyIdFromName = (propertyName: string) => {
        return userInfo?.properties.find((p) => p.name === propertyName)?.id || ''
    }

    const storeCurrencyInLocalStorage = (propertyName: string) => {
        const currency = userInfo?.properties.find((p) => p.name === propertyName)?.currency || '$'
        localStorage.setItem('currency', currency);
    }

    const onFilterChange = (filters: { propertyName: string; formOfSale: 'dorm' | 'room' | 'global' | 'apart'; period: string }) => {
        setActualFilters(filters)
    }

    const onFiltersApply = async () => {
        if (!backendServices) return
        const { propertyName, period, formOfSale } = actualFilters

        storeCurrencyInLocalStorage(propertyName)

        if (propertyName === 'Grupo Che') {
            const res = await backendServices?.getBudgetOrganizationScreen({
                organizationID: userInfo?.organizations?.find(r => r.name.toLowerCase().includes('grupo'))?.id ?? "", 
                periods: period, 
                formOfSale, 
                orderBy: 'revenue,occupancy'
            })
            setBudgetData(res.data)
        } else {
            const propertyID = getPropertyIdFromName(propertyName)
            const res = await backendServices?.getBudgetScreen({propertyID, period, formOfSale, orderBy: 'revenue,occupancy' })
            setBudgetData(res.data)
        }
    }

    const handleBackToConsole = ()=>{
        localStorage.removeItem('idToken')
        window.location.href=`${process.env.REACT_APP_ROIBI_URI}`
    }

    const urlAuthCode = new URLSearchParams(window.location.search)
    const code = urlAuthCode.get('code')

    useEffect(() => {
        setCheckboxFilters({
            Budget: true,
            Now: true,
            ToGo: true,
            LastYear: true,
        })
    }, [lastAnalysisMetrics])

    useEffect(() => {
        if (!properties || !properties.length || !backendServices) return
        const initialFilter = { propertyName: properties[0].name, formOfSale: 'global' as const, period: format(new Date(), 'yyyy-MM') }
        setActualFilters(initialFilter)
        backendServices
            .getBudgetScreen({
                propertyID: getPropertyIdFromName(properties[0].name),
                period: initialFilter.period,
                formOfSale: initialFilter.formOfSale,
                orderBy: 'revenue,occupancy',
            })
            .then((resultBudget) => {
                setBudgetData(resultBudget?.data)
            })
    }, [userInfo])

    useEffect(() => {
        backendServices?.sendCloudBedsCodeBackend(code)
    }, [])
    
    useEffect(() => {    
        if (typeof budgetData !== 'undefined' && Object.keys(budgetData).length !== 0) {
            setBudgetDataLoaded(true);
    
            const allMetricsZero = Object.values(budgetData.lastTotalAnalysis.analysis.metrics).every(metric => (
                Object.values(metric).every(value => value === 0)
            ));
    
            if (allMetricsZero) {
                setShowNoDataModal(true);
            } else {
                setShowNoDataModal(false);
            }
        } else {
            setShowNoDataModal(true);
        }
    }, [budgetData]);

    // eslint-disable-next-line no-constant-condition
    return !userInfo || !budgetData || loading || !budgetDataLoaded ? (
        <LoadingScreen />
    ) : (
        <>
            <div>
                <FiltersBar applyFilters={onFiltersApply} properties={properties} actualFilters={actualFilters} onFilterChange={onFilterChange} />
                <div
                    className={classnames(`budget-screen`, {
                        mobile: !isDesktop,
                    })}
                >
                    <div className="forecast-title">
                        <Typography>Forecast</Typography>
                        <p className='datadamus'>(c) Predictive modeling by DataDamus</p>
                    </div>
                    <div className="info-container">
                        <div className="cards-with-selectors">
                            {isMobile && (
                                <div className="breadcrumbs-mobile">
                                    <FilterBreadcrumb onFilterChange={onFilterChange} actualFilters={actualFilters} properties={properties} />
                                </div>
                            )}
                            <div className="cards-container">
                                <Grid container className="cards-row">
                                    <BudgetCards checkboxFilters={checkboxFilters} data={data} />
                                </Grid>
                            </div>
                            {/* {isDesktop && (
                                <div className="selectors-container">
                                    {periodTypes.map((type) => (
                                        <FormControlLabel
                                            key={type}
                                            onChange={() => toggleCheckbox(type)}
                                            checked={checkboxFilters[type]}
                                            className={'date-checkbox ' + type.toLowerCase().replace(' ', '-')}
                                            control={<Checkbox />}
                                            label={formatMessage(BudgetMessages[`budget${type}`])}
                                        />
                                    ))}
                                </div>
                            )} */}
                        </div>
                    </div>
                    <div className="map-and-advertising-container">
                        {isDesktop && (
                            <div className="table">
                                {budgetData.lastAnalysis.length === 0 ? (
                                    <SkeletonForCountryTable columnName={formatMessage(BudgetMessages.budgetRevenue)}/>
                                ) : (
                                    <CountriesDataTable
                                        data={convertRevenueTableAlpha3('revenue', budgetData) || []}
                                        columnName={formatMessage(BudgetMessages.budgetRevenue)}
                                    />
                                )}
                            </div>
                        )}

                        <div className="world-map-and-buttons">
                            {!isMobile && (
                                <div className="map">
                                    <WorldMap data={ocuppancyToWorldMapData.slice(0, 10)} />
                                </div>
                            )}
                            <div>
                                <RadioGroup
                                    className="country-radio-buttons"
                                    value={worldMapDataType}
                                    onChange={(event) => setWorldMapDataType(event.target.value as ChartTitle)}
                                >
                                    {allWorldMapDataTypes.map((type) => (
                                        <FormControlLabel
                                            className=""
                                            control={<Radio value={type} />}
                                            label={
                                                type === 'Revenue'
                                                    ? `${formatMessage(BudgetMessages.budgetRevenue)}`
                                                    : `${formatMessage(BudgetMessages.budgetOccupancy)}`
                                            }
                                            key={type}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>
                        </div>
                        <div className="table">
                            {budgetData.lastAnalysis.length === 0 ? (
                                <SkeletonForCountryTable columnName={ `${formatMessage(BudgetMessages.budgetOccupancy)}`}/>
                            ) : (
                                <CountriesDataTable
                                    columnName={
                                        !isDesktop && worldMapDataType === 'Revenue' ? 'Revenue' : `${formatMessage(BudgetMessages.budgetOccupancy)}`
                                    }
                                    data={
                                        !isDesktop && worldMapDataType === 'Revenue'
                                            ? convertRevenueTableAlpha3('revenue', budgetData) || []
                                            : convertRevenueTableAlpha3('occupancy', budgetData) || []
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                className="no-data-modal"
                open={showNoDataModal}
                onClose={() => setShowNoDataModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box>
                    <div className="title-and-close-modal">
                        <IconButton aria-label="Cerrar" onClick={() => setShowNoDataModal(false)}>
                            <CloseIcon />
                        </IconButton>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {formatMessage(BudgetMessages.noDataToShow)}
                        </Typography>
                    </div>
                    <Button style={{ backgroundColor: '#0058D6', color: 'white', padding: '10px 25px' }} onClick={handleBackToConsole}>{formatMessage(BudgetMessages.backToRoibiConsole)}</Button>
                </Box>
            </Modal>
        </>
    )
}

export default BudgetScreen
