import React, { FunctionComponent, memo } from 'react'
import { Grid } from '@mui/material'
import RoiBarChart from '../../commons/RoiBarChart/RoiBarChart'
import { useIntl } from 'react-intl'
import { BudgetMessages } from '../../../lang/Messages'

interface OwnProps {
    data: Record<
        ChartTitle,
        {
            Budget: number
            Now: number
            ToGo?: number
            LastYear: number
        }
    >
    checkboxFilters: Record<ChartPeriod, boolean>
}

type Props = OwnProps

const barColors = {
    Budget: {
        barColorsBack: 'rgba(69, 215, 149, .2)',
        barColor: 'rgba(69, 215, 149, 1)',
    },
    Now: {
        barColorsBack: 'rgba(0, 75, 255, .2)',
        barColor: 'rgba(0, 75, 255, 1)',
    },
    ToGo: {
        barColorsBack: 'rgba(207, 108, 255, .2)',
        barColor: 'rgba(207, 108, 255, 1)',
    },
    LastYear: {
        barColorsBack: 'rgba(39, 185, 245, .2)',
        barColor: 'rgba(39, 185, 245, 1)',
    },
}

const budgetsTypes: ChartTitle[] = ['Revenue', 'RevPAR', 'ADR', 'Occupancy']
const BudgetCards: FunctionComponent<Props> = (props) => {
    const { data, checkboxFilters } = props
    const getBarDataChart = (type: ChartTitle) => {
        const periods: Array<ChartPeriod> = Object.keys(data[type]) as ChartPeriod[]
        const values: number[] = periods.map((key) => (data[type][key] || 0))
        const maxValue = Math.max(...values)
        const filterValues = periods.map((key) => (checkboxFilters[key] ? (data[type][key] || 0) : 0))
        const realValueColorArray = periods.map((period) => barColors[period as keyof typeof barColors].barColor)
        const overlapBarColorArray = periods.map((period) => barColors[period as keyof typeof barColors].barColorsBack)

        return {
            labels: periods.map((period) => formatMessage(BudgetMessages[`budget${period}`])),
            datasets: [
                {
                    label: 'type1',
                    data: filterValues,
                    backgroundColor: realValueColorArray,
                    borderColor: realValueColorArray,
                    barColor: realValueColorArray,
                    borderWidth: 1,
                    borderRadius: {
                        topLeft: 10,
                        topRight: 10,
                        bottomLeft: 10,
                        bottomRight: 10,
                    },
                },
                {
                    label: 'type2',
                    data: filterValues.map((value) => maxValue - (value||0)),
                    backgroundColor: overlapBarColorArray,
                    borderColor: overlapBarColorArray,
                    barColor: overlapBarColorArray,
                    borderWidth: 1,
                    borderRadius: {
                        topLeft: 10,
                        topRight: 10,
                        bottomLeft: 10,
                        bottomRight: 10,
                    },
                },
            ],
        }
    }

    const { formatMessage } = useIntl()

    return (
        <>
            {budgetsTypes.map((type) => {
                return <Grid item xs={12} sm={6} md={6} lg={3} key={type}>
                    <RoiBarChart
                        graphData={getBarDataChart(type)}
                        title={formatMessage(BudgetMessages[`budget${type}`])}
                        vertical={type === 'Occupancy'}
                    />
                </Grid>
            })}
        </>
    )
}

export default memo(BudgetCards)
