import {ChangeEvent, useState} from 'react';
import { TextField, Button, FormControl, FormControlLabel, FormHelperText, Checkbox, InputAdornment, IconButton } from '@mui/material'
import {loginWithUserAndPass} from '../../../services/firebase';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const LoginForm = () => {
    const [userNameAndPass, setUserNameAndPass] = useState<{ email?: string; password?: string }>({
      email: undefined,
      password: undefined,
    });
  
    const [showPassword, setShowPassword] = useState(false);
  
    const onChange = (key: 'email' | 'password', event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUserNameAndPass((prevUser) => ({
        ...prevUser,
        [key]: event.target.value,
      }));
    };
  
    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();
      // Función para validar las credenciales del usuario y redirigir al usuario a Budget
    };
  
    const [rememberMe, setRememberMe] = useState(false);
  
    const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRememberMe(event.target.checked);
    };

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
  
    
    const onSubmit = () => {
        if (!userNameAndPass.email || !userNameAndPass.password) {
            setErrorMessage("Please enter email and password");
            return;
        }

     loginWithUserAndPass(userNameAndPass as { email: string; password: string }).catch(()=>{
        setErrorMessage("Usuario y/o contraseña incorrecta");
     })
    };  
  
    return (
      <div className="form">
        <div className="title">
          <h1>Sign In</h1>
          <p>Welcome back, please login.</p>
        </div>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <TextField
              label="Email"
              type="email"
              value={userNameAndPass.email}
              onChange={(event) => onChange('email', event)}
              required
              variant="filled"
            />
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              value={userNameAndPass.password}
              onChange={(event) => onChange('password', event)}
              required
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errorMessage && (
                <FormHelperText error>{errorMessage}</FormHelperText>
            )}
            <div className="checkbox-and-forgot-password">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                    color="primary"
                  />
                }
                label="Remember me"
              />
              <FormHelperText>
                <a href="#">Forgot password?</a>
              </FormHelperText>
            </div>
  
            <Button className="login-button" onClick={onSubmit}>
              Sign in
            </Button>
          </FormControl>
        </form>
        <p className="beta-sign">Beta version</p>
      </div>
    );
  };

export default LoginForm