import { FC, useState } from 'react'
import './CardWithDoughnutChart.scss'
import Typography from '@mui/material/Typography'
import { Box, Card, CardContent, Checkbox, FormControlLabel } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { RoiDsIcon } from '../../../assets/Icons'

ChartJS.register(ArcElement, ChartDataLabels, Tooltip, Legend)

type CardProps = {
    data: number[]
    title: string
}

const optionsDoughnut: ChartOptions<'doughnut'> = {
    plugins: {
        datalabels: {
            display: true,
            color: 'white',
        },
        
        legend: {
            display: false,
        },
    },
    animation: {
        animateRotate: false,
    },

    responsive: true,
    maintainAspectRatio: false,
}

const CardWithDoughnutChart: FC<CardProps> = (props) => {
    const { title, data } = props
    const [checkBoxIndex, setCheckBoxIndex] = useState<Record<number, boolean>>({
        0: true,
        1: true,
        2: true,
    })
    const toggleChecked = (index: number, checked: boolean) => {
        setCheckBoxIndex({ ...checkBoxIndex, [index]: checked })
    }

    const donughtLabelsData = [
        {
            backgroundColor: 'rgb(25, 118, 210)',
            borderColor: 'rgb(25, 118, 210)',
        },
        {
            backgroundColor: 'rgb(102, 162, 222)',
            borderColor: 'rgb(102, 162, 222)',
        },
        {
            backgroundColor: 'rgb(167, 201, 234)',
            borderColor: 'rgb(167, 201, 234)',
        },
    ]

    const getLabelDataFromKey = (key: 'backgroundColor' | 'borderColor') =>
        [
            ...[checkBoxIndex[0] ? donughtLabelsData[0][key] : undefined],
            ...[checkBoxIndex[1] ? donughtLabelsData[1][key] : undefined],
            ...[checkBoxIndex[2] ? donughtLabelsData[2][key] : undefined],
        ].filter((item) => !!item)

    const getFilteredData = () =>
        [
            ...[checkBoxIndex[0] ? data[0] : undefined],
            ...[checkBoxIndex[1] ? data[1] : undefined],
            ...[checkBoxIndex[2] ? data[2] : undefined],
        ].filter((item) => !!item)

    const dataDoughnut = {
        labels: ['Direct', "OTA's", 'Others'],
        datasets: [
            {
                label: '',
                data: getFilteredData(),
                backgroundColor: getLabelDataFromKey('backgroundColor'),
                borderColor: getLabelDataFromKey('borderColor'),
                borderWidth: 1,
            },
        ],
    }

    return (
        <Card className='donut-chart-dashboard'>
            <div className='card-with-donut-chart'>
                <div className='title-and-checkboxes-container'>
                    <Typography color="text.secondary" gutterBottom className='donut-chart-title'>
                        <RoiDsIcon /> {title}
                    </Typography>
                
                    <CardContent className="occupancy-and-checkboxes">
                        <Typography>Occupancy</Typography>
                        <FormControlLabel
                            checked={checkBoxIndex[0]}
                            onChange={(e, checked) => toggleChecked(0, checked)}
                            className="checkbox-direct"
                            control={<Checkbox name="Direct" />}
                            label="Direct"
                        />
                        <FormControlLabel
                            className="checkbox-ota"
                            checked={checkBoxIndex[1]}
                            onChange={(e, checked) => toggleChecked(1, checked)}
                            control={<Checkbox name="OTA's" />}
                            label="OTA's"
                        />
                        <FormControlLabel
                            checked={checkBoxIndex[2]}
                            onChange={(e, checked) => toggleChecked(2, checked)}
                            className="checkbox-others"
                            control={<Checkbox name="Others" />}
                            label="Others"
                        />
                    </CardContent>
                </div>
                
                <div className="chart-container">
                    <Doughnut data={dataDoughnut} options={optionsDoughnut} plugins={[ChartDataLabels as any]}/> {/*TODO: sacar any*/}
                </div>

            </div>
        </Card>
    )
}

export default CardWithDoughnutChart
