import React, { FC, ReactElement, Ref, useState } from 'react'
import { IconButton, Modal, Popover } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { useIsMobile } from '../../../utils/hooks'

interface Props {
    title: string,
    textPopover: string
}

const InfoPopover: FC<Props> = (props) => {
    const { title, textPopover } = props
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null)
    }
    return (
        <>
            <IconButton onClick={handleClick}>
                <InfoOutlinedIcon></InfoOutlinedIcon>
            </IconButton>
            <ModalOrPopover anchorEl={anchorEl} handleClose={handleClose}>
                <div className="card-with-info">
                    <div className="title-and-close-button">
                        <h3 className="title-card-info">{title}</h3>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <p className="text-card-info">{textPopover}</p>
                </div>
            </ModalOrPopover>
        </>
    )
}

type ModalOrPopoverProps = {
    children: ReactElement
    anchorEl: HTMLElement | null
    handleClose: () => void
}
const ModalOrPopover: FC<ModalOrPopoverProps> = (props) => {
    const { children, anchorEl, handleClose } = props

    const isMobile = useIsMobile()

    return isMobile ? (
        <Modal open={Boolean(anchorEl)} onClose={handleClose} className="budget-card-info modal">
            {children}
        </Modal>
    ) : (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            PaperProps={{
                className: 'budget-card-info popover',
            }}
        >
            {children}
        </Popover>
    )
}
export default InfoPopover
