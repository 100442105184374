import { FC } from 'react'
import './CardWithLineChart.scss'
import Typography from '@mui/material/Typography'
import { Box, Card, Chip, CardContent, Grid } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, ChartOptions,
} from 'chart.js';
import { buildPointRadius } from '../../../utils/graphUtils'
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(CategoryScale, ChartDataLabels, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

type CardProps = {
    chipLabel: string
    graphData: {
        labels: string[]
        datasets: {
            label: string
            data: number[]
        }[]
    }
    graphOptions?: {
        borderColor: string
        backgroundColor: string
        lineTension: number
    }
    title: string
    value: string
}

const optionsLine: ChartOptions<'line'> = {
    plugins: {
        legend: {
            display: false,
        },
        datalabels: {
            display: false,
        },
    },
    responsive: true,

    maintainAspectRatio: true,

    scales: {
        x: {
            display: false,
            grid: {
                display: false,
            },
            ticks: {
                callback: () => '',
            },
        },
        y: {
            display: false,
            grid: {
                display: false,
            },
            ticks: {
                callback: () => '',
            },
        },
    },
}

const defaultGraphOptions = {
    borderColor: 'rgb(0, 176, 67)',
    backgroundColor: 'rgb(0, 176, 67)',
    lineTension: 0.8,
}

const CardWithLineChart: FC<CardProps> = (props) => {
    const { chipLabel, graphData, title, value, graphOptions = defaultGraphOptions } = props

    const graphDataWithStyles = {
        ...graphData,
        datasets: graphData.datasets.map((dataset) => ({
            ...dataset,
            pointRadius: buildPointRadius(dataset.data),
            ...graphOptions,
        })),
    }

    return (
        <Card className='line-chart-dashboard'>
            <div className='card-with-line-chart'>
                <div
                    style={{
                        flexBasis: '35%',
                    }}
                >
                    <div className='line-chart-title'>
                        <Typography color="text.secondary" gutterBottom>
                            {title}
                        </Typography>   
                    </div>

                    <div className='line-chart-value'>
                       <Typography component="div">${value}</Typography> 
                    </div>
                    
                    <div className='line-chart-success-chip'>
                      <Chip
                        icon={<ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                        label={chipLabel}
                        color="success"
                        variant="outlined"
                        />  
                    </div>
                </div>
                <div
                    style={{
                        flexBasis: '60%',
                    }}
                >
                    <div className='chart-line-data1'>
                        <Typography  color="text.secondary" gutterBottom>
                            Sep 29th
                            $600000
                        </Typography>
                    </div>

                    <div className='chart-line'>
                        <Line height='100%' options={optionsLine} data={graphDataWithStyles} />
                    </div>

                    <div className='chart-line-data2'>
                        <Typography color="text.secondary" gutterBottom>
                            Sep 29th
                        </Typography>
                        <Typography color="text.secondary" gutterBottom>$600000</Typography>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default CardWithLineChart
