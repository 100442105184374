import { Button, Typography } from '@mui/material'
import { logInWithGoogle } from '../../../services/firebase'
import React, { FC, useContext } from 'react'
import { UserContext } from '../../../App'
import { Navigate } from 'react-router-dom'
import LoginForm from './LoginForm'
import './LoginScreen.scss'
import loginBudgetLogo from '../../../assets/login-budget-logo.svg'
import '../../../assets/login-screen-background.svg'
import '../../../assets/login-smalls-screen-background.svg'
import '../../../assets/login-responsive-screen-background.svg'

export const LoginScreen: FC = () => {
    const { user, userLoading } = useContext(UserContext)
    const urlAuthCode = new URLSearchParams(window.location.search);
    const code = urlAuthCode.get('code');

    if (userLoading) return null
    return user ? (
        !code ? <Navigate to='/' replace />:
        <Navigate to={`/?code=${code}`} replace />
    ) : (
        <div className="login-screen">
            <div className="login-budget-logo">
                <div className="logo">
                    <img src={loginBudgetLogo} />
                </div>
            </div>

            <div className="login-form">
                <LoginForm />
            </div>
        </div>
    )
}