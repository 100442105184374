import { FC } from 'react'
import './SkeletonsForCountryTables.scss'
import { Skeleton, Typography } from '@mui/material'
import InfoPopover from '../../screens/BudgetScreen/PopupInfoCharts'
import { useIntl } from 'react-intl'
import { BudgetMessages } from '../../../lang/Messages'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

type Props = {
    columnName: string
}

const SkeletonForCountryTable: FC<Props> = (props) => {
    const { columnName } = props
    const { formatMessage } = useIntl()
    return (
        <div className="skeleton-root">
            <div className="skeleton-header">
                <div className="info-popup-table">
                    <InfoPopover
                        title={
                            columnName === 'Revenue'
                                ? `${formatMessage(BudgetMessages.budgetRevenue)}`
                                : columnName === 'Ingresos'
                                ? `${formatMessage(BudgetMessages.budgetRevenue)}`
                                : `${formatMessage(BudgetMessages.budgetOccupancy)}`
                        }
                        textPopover={
                            columnName === 'Revenue'
                                ? `${formatMessage(BudgetMessages.budgetCountryRevenueDescription)}`
                                : columnName === 'Ingresos'
                                ? `${formatMessage(BudgetMessages.budgetCountryRevenueDescription)}`
                                : `${formatMessage(BudgetMessages.budgetCountryOccupancyDescription)}`
                        }
                    />
                </div>
                <div className="country-and-data">
                    <Typography className="skeleton-text">{formatMessage(BudgetMessages.country)}</Typography>
                    <Typography className="skeleton-text">{columnName}</Typography>
                </div>
            </div>
            <div className="skeleton-body">
                <div className="skeleton-div">
                    <Skeleton variant="rounded" width={'20%'} height={40} />
                    <Skeleton variant="rounded" width={'80%'} height={40} />
                </div>
                <div className="skeleton-div">
                    <Skeleton variant="rounded" width={'20%'} height={40} />
                    <Skeleton variant="rounded" width={'80%'} height={40} />
                </div>
                <div className="skeleton-div">
                    <Skeleton variant="rounded" width={'20%'} height={40} />
                    <Skeleton variant="rounded" width={'80%'} height={40} />
                </div>
                <div className="skeleton-div">
                    <Skeleton variant="rounded" width={'20%'} height={40} />
                    <Skeleton variant="rounded" width={'80%'} height={40} />
                </div>
                <div className="skeleton-div">
                    <Skeleton variant="rounded" width={'20%'} height={40} />
                    <Skeleton variant="rounded" width={'80%'} height={40} />
                </div>
                <div className="skeleton-div">
                    <Skeleton variant="rounded" width={'20%'} height={40} />
                    <Skeleton variant="rounded" width={'80%'} height={40} />
                </div>
            </div>
            <div className="advertising">
                <div
                    style={{
                        backgroundColor: '#FFF1D6',
                        width: '100%',
                        padding: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '8px 8px 0 0',
                    }}
                >
                    <WarningAmberIcon />
                </div>
                <Typography style={{ textAlign: 'center', margin: '30px 0', color: 'rgba(0, 0, 0, 0.60)'}}>{formatMessage(BudgetMessages.noDataToShow)}</Typography>
            </div>
        </div>
    )
}

export default SkeletonForCountryTable
