import { FC } from 'react'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
} from '@mui/material'
import Typography from '@mui/material/Typography'
// import {Bar, BarChart, ResponsiveContainer} from 'recharts';

type InfoCardsProps = {
    title: string
    description?: string
    className?: string
    img?: string
    chartData: any
    dataKey: string
}

const InfoCard: FC<InfoCardsProps> = (props) => {
    const { title, description, className, img, chartData, dataKey } = props
    return (
        <Card className={className}>
            {img && (
                <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={img}
                />
            )}
            <CardContent sx={{p:'16px 16px 0 16px'}}>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
                {/* <ResponsiveContainer width="95%" height={100}>
                    <BarChart  data={chartData}>
                        <Bar dataKey={dataKey} fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer> */}
            </CardContent>
            <CardActions>
                <Button size="small">Mas info</Button>
            </CardActions>
        </Card>
    )
}

export default InfoCard
